import { createStore, compose, applyMiddleware, bindActionCreators } from 'redux';
import { root_reducer } from './root_reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';

import { iRedux_state } from '../interfaces';
import { show_redux } from '../settings';

import * as app_action_creators from './app/app_actions';
import * as chromia_action_creators from './chromia/chromia_actions';
import * as basic_action_creators from './basic/basic_actions';
import * as pro_action_creators from './pro/pro_actions';
import * as AM_action_creators from './approve_modal/AM_actions';
import * as OBM_action_creators from './order_book_modal/OBM_actions';
import * as MOM_action_creators from './make_order_modal/MOM_actions';
import * as RUBM_action_creators from './request_uncreate_borrow_modal/RUBM_actions';
import * as IM_action_creators from './info_modal/IM_actions';
import * as TOM_action_creators from './take_order_modal/TOM_actions';
import * as UIActionCreators from './UI/UIActions';

declare global{
	interface Window{
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : any;
	}
}

const composeEnhancers = (show_redux && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose ;

export const store = createStore(root_reducer, composeEnhancers(
	applyMiddleware(thunk)
));

export type Dispatch = typeof store.dispatch;

export const useStoreDispatch = () => {		// combine all actions and wrapps it in dispatch
	const dispatch = useDispatch();
	let all_action_creators = {
		...app_action_creators,
		...chromia_action_creators,
		...pro_action_creators,
		...basic_action_creators,
		...AM_action_creators,
		...OBM_action_creators,
		...MOM_action_creators,
		...RUBM_action_creators,
		...IM_action_creators,
		...TOM_action_creators,
		...UIActionCreators,
	};
	return bindActionCreators(all_action_creators, dispatch);
}

export const use_store_selector: TypedUseSelectorHook<iRedux_state> = useSelector;	// gives access to redux store