import { maturity_date } from "../../interfaces";

export enum basic_action_types {
	SET_CURRENT_STEP = 'BASIC/SET_CURRENT_STEP',
	SET_CURRENT_F_TOKEN = 'BASIC/SET_CURRENT_F_TOKEN',
	SET_CURRENT_U_TOKEN = 'BASIC/SET_CURRENT_U_TOKEN',
	SET_CURRENT_DIRECTION = 'BASIC/SET_CURRENT_DIRECTION',
	SET_CURRENT_MATURITY_DATE = 'BASIC/SET_CURRENT_MATURITY_DATE',
	SET_CURRENT_STRIKE = 'BASIC/SET_CURRENT_STRIKE',
	STEP_BACK = 'BASIC/STEP_BACK',
}

export type basic_action =
	set_current_step_action |
	set_current_f_token_action |
	set_current_u_token_action |
	set_current_direction_action |
	set_current_maturity_date_action |
	set_current_strike_action |
	step_back_action
;

interface set_current_step_action {
	type: basic_action_types.SET_CURRENT_STEP,
	payload: number
}

interface set_current_f_token_action {
	type: basic_action_types.SET_CURRENT_F_TOKEN,
	payload: string
}

interface set_current_u_token_action {
	type: basic_action_types.SET_CURRENT_U_TOKEN,
	payload: string
}

interface set_current_direction_action {
	type: basic_action_types.SET_CURRENT_DIRECTION,
	payload: 'up' | 'down'
}

interface set_current_maturity_date_action {
	type: basic_action_types.SET_CURRENT_MATURITY_DATE,
	payload: maturity_date
}

interface set_current_strike_action {
	type: basic_action_types.SET_CURRENT_STRIKE,
	payload: number
}

interface step_back_action {
	type: basic_action_types.STEP_BACK
}