import { app_action_types } from "../app/app_types"
import { Dispatch } from "../store"
import { IM_action_types } from "./IM_types"

export function close_info_modal() {
	return (dispatch : Dispatch) => {
	   dispatch({
		   type: IM_action_types.CLOSE_MODAL
	   });
	   dispatch({
		   type: app_action_types.CLOSE_CHOOSEN_WINDOW,
		   payload: 'info',
	   });
	}
}

export function call_info_modal(message: string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: IM_action_types.CALL_INFO_MODAL,
			payload: message
		});
		dispatch({
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: 'info'
		});
	}
}