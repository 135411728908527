import { app_action_types } from "../app/app_types"
import { Dispatch } from "../store"
import { obm_action_types } from "./OBM_types"

export function call_order_book_modal(option_id: number | string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: obm_action_types.CALL_MODAL,
			payload: option_id
		})
		dispatch({
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: 'order_book'
		})
	}
}

export function close_order_book_modal() {
	return (dispatch : Dispatch) => {
		dispatch({
			type: obm_action_types.CLOSE_MODAL,
		})
		dispatch({
			type: app_action_types.CLOSE_CHOOSEN_WINDOW,
			payload: 'order_book'
		})
	}
}

export function set_is_obm_buttons_blocked(new_state: boolean) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: obm_action_types.SET_IS_BUTTONS_BLOCKED,
			payload: new_state
		})
	}
}