import { BN_to_val, val_to_BN } from "../../common_funcs";
import { iBalance, iBalance_response, iOption } from "../../interfaces";
import { store } from "../../store/store";
import { Option } from "./option";


export class Balance implements iBalance{
	full_balance: string;
	exercise_balance: string;
	locked_balance: string;
	created_balance: string;
	borrow_balance: string;

	available_balance: string;
	is_showed_your_desc: boolean;
	is_showed_created_desc: boolean;
	full_ex_button_showed = false;      // basic
	cancel_ex_button_showed = false;    // basic
	finalize_ex_button_showed = false;
	uncreate_button_showed = false;
	withdraw_button_showed = false;
	borrow_button_showed = false;
	opt: iOption;

	opt_id: string | undefined;     // chromia field
	is_showed_chr_desc: boolean;


	constructor (response_balance: iBalance_response){
		this.full_balance = response_balance.full_balance;
		this.exercise_balance = response_balance.exercise_balance;
		this.locked_balance = response_balance.locked_balance;
		this.created_balance = response_balance.created_balance;
		this.borrow_balance = response_balance.borrow_balance;

		const { system_timestamp, config } = store.getState().app;
		if (system_timestamp === null || !config) throw new Error(
			'Error code: 5_0' + 
			(system_timestamp?'' : '\ntime: -') + 
			(config? '' : '\nconfig: -')
		);
	
		let opt = response_balance.opt;
		let autoexercise_time = config.eopt.autoexercise_time * 60;

		// available_balance
		this.available_balance = BN_to_val ( val_to_BN(response_balance.full_balance).sub( val_to_BN(response_balance.exercise_balance) ).sub( val_to_BN(response_balance.locked_balance) ).toString() );

		// is showed for your_options_table
		let is_autoexercised = +opt.maturity_price > 0
			? opt.call_type
				? opt.maturity_price > opt.strike
				: opt.maturity_price < opt.strike
			: false
		;
		let has_balance = opt.maturity_time + autoexercise_time > system_timestamp && +response_balance.full_balance > 0;
		let has_ex_balance = opt.maturity_time + autoexercise_time < system_timestamp && +response_balance.exercise_balance > 0;
		this.is_showed_your_desc = is_autoexercised || has_balance || has_ex_balance;

		// is showed for created_options_table
		this.is_showed_created_desc = +response_balance.created_balance > 0;

		// buttons
		if ( system_timestamp > opt.maturity_time + autoexercise_time ){  // option has expired and autoexercise time has passed
			if (+response_balance.exercise_balance > 0){
				this.finalize_ex_button_showed = true;
			}
			if (+response_balance.created_balance > 0){
				this.withdraw_button_showed = true;
			}
		}
		if (system_timestamp < opt.maturity_time ) {    // option not expired
			if (+response_balance.created_balance > 0 && +this.available_balance > 0){
				this.uncreate_button_showed = true;
			}
			if (+this.available_balance > 0){
				this.full_ex_button_showed = true;
			}
			if (+response_balance.exercise_balance > 0){
				this.cancel_ex_button_showed = true;
			}
			if ((+response_balance.borrow_balance > 0 || +this.available_balance > 0) && opt.call_type){  // available only for calls
				this.borrow_button_showed = true;
			}
		}

		// opt
		this.opt = new Option(response_balance.opt);

		// chromia
		this.opt_id = response_balance.opt_id?.toString('hex');
		this.is_showed_chr_desc = system_timestamp < opt.maturity_time
			? true  // not expired
			: +this.available_balance > 0
		;
	}
}