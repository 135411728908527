import { createAmount } from "@chromia/ft4";

import { iGetActiveOptionsBalancesQueryResponse, iGetActiveOptionsQueryResponse, iGetAllOptionsQueryResponse, iGetClientOptionBalancesQueryResponse, iGetOptionByIdQueryResponse, iGetOptionOrdersQueryResponse } from "../../../chromia_interfaces";
import { store } from "../../../store/store";
import { getChrClient } from "../helpers";

export const getChrTime = async () => {
	const res: number = await (await getChrClient()).query("hedget.GetTime");

	// console.log('hedget.GetTime: ', res);

	return res;
}

export const getAllAssetsQuery = async () => {

	const res: any = await (await getChrClient()).query("ft4.get_all_assets", { page_size: 1000, page_cursor: null });

	// console.log("res: ", res);

	// let res_arr: Asset[] = [];
	let res_arr: any[] = [];
	for (let i = 0; i < res.data.length; ++i) {
		//res_str += (arr[i] as string) + "\n";
		res_arr.push({
			id: res.data[i].id,
			id_str: res.data[i].id.toString('hex'),
			symbol: res.data[i].symbol,
			name: res.data[i].name,
			dec: res.data[i].decimals,
			tot_supply: createAmount(res.data[i].supply, res.data[i].decimals),
			supply: res.data[i].supply.toString()
		})
	}
	console.log('res_arr: ', res_arr);
	// set_assets_arr(res_arr);
	return res_arr;
}

export const getParamsQuery = async () => {
	const res = await (await getChrClient()).query("hedget.GetPrms") as any;
	console.log("params: ", res, '\n\tcom_tok: ', res.com_tok.toString('hex'), '\n\towner: ', res.owner.toString('hex'));
	return res;
}

export const getAllOptionsQuery = async () => {
	const res = await (await getChrClient()).query("hedget.GetAllOpts") as unknown as iGetAllOptionsQueryResponse[];
	// console.log("all options: ", res);
	return res;
}

export const getActiveOptionsQuery = async () => {
	const res = await (await getChrClient()).query("hedget.GetActiveOpts") as unknown as {opt: iGetActiveOptionsQueryResponse}[];
	// console.log("active options: ", res);
	return res;
}


/**
 * useless - ?
 */
export const getActiveOptionsBalancesQuery = async () => {
	const res = await (await getChrClient()).query("hedget.GetActiveOptBals") as unknown as iGetActiveOptionsBalancesQueryResponse[];
	// console.log("active options balances: ", res);
	return res;
}

export const getClientOptionBalancesQuery = async () => {
	const { client_addr } = store.getState().chromia;
	if (!client_addr) throw new Error('');

	const res = await (await getChrClient()).query("hedget.GetNonzeroBals", {from: client_addr}) as unknown as iGetClientOptionBalancesQueryResponse[];

	// console.log("get_client_options_bals: ", res);

	return res;
}

export const getOptionOrdersQuery = async (optionId: string) => {
	// const res = await (await getChrClient()).query("hedget.GetOptOrds", {opt_id: optionId}) as unknown as iGetOptionOrdersQueryResponse[];	// returns ords with zero amount
	const res = await (await getChrClient()).query("hedget.GetNonzeroOptOrds", {opt_id: optionId}) as unknown as iGetOptionOrdersQueryResponse[];

	// console.log("get option orders: ", res);

	return res;
}

export const getClientOrdersQuery = async () => {
	const { client_addr } = store.getState().chromia;
	if (!client_addr) throw new Error('');

	const res = await (await getChrClient()).query("hedget.GetNonzeroOrds", {from : client_addr}) as unknown as iGetOptionOrdersQueryResponse[];

	// console.log("get client orders: ", res);

	return res;
}

export const getOptionByIdQuery = async (opt_id: string) => {
	const res = await (await getChrClient()).query("hedget.GetOptById", {opt_id}) as unknown as iGetOptionByIdQueryResponse;

	// console.log('getOptionById ', opt_id, ' : ', res);

	return res;
}

export const getOrderPrice = async (ord_id: number | string) => {	// number
	const res = await (await getChrClient()).query("hedget.GetOrdPrice", {ord_id}) as unknown as number;

	// console.log('GetOrderPrice ', ord_id, ' : ', res);

	return res;
}