import { iChromia_state } from "../../chromia_interfaces"
import { chromia_action, chromia_action_types } from "./chromia_types";

const initial_state: iChromia_state = {
	client_addr: null,
	// account_id: null,
	// key_pair: null,
	// descriptor:  null,
	// account_iid: null,
	asset_balances: [],

	chrClient: null,
	session: null,
}

export const chromia_reducer = ( state = initial_state, action: chromia_action) : iChromia_state => {
	switch (action.type){

		case chromia_action_types.CLEAR_STATE:
			return initial_state;

		case chromia_action_types.SET_ACCOUNT_DETAILS:
			return {...state, ...action.payload};

		case chromia_action_types.SET_ASSET_BALANCE:
			let new_asset_balances = state.asset_balances.slice();
			let asset_balance = new_asset_balances.find( balance => balance.asset === action.payload.asset);
			if (asset_balance){
				asset_balance.balance = action.payload.balance;
				// asset_balance.locked_balance = action.payload.locked_balance;
			} else {
				new_asset_balances.push(action.payload);
			}
			return {...state, asset_balances: new_asset_balances};

		case chromia_action_types.SET_CHR_CLIENT:
			return {...state, chrClient: action.payload};

		case chromia_action_types.SET_SESSION:
			return {...state, session: action.payload};

		default: return state;
	}
}