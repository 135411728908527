import { iPro_state } from "../../interfaces";
import { pro_action, pro_action_types } from "./pro_types";

const initial_state: iPro_state = {
	current_f_token: null,
	current_u_token: null,
	current_maturity_date: null,
	// options_period: 'weekly',
	options_period: 'daily',
}

export const pro_reducer = ( state = initial_state, action: pro_action) : iPro_state => {
	switch (action.type) {

		case pro_action_types.SET_CURRENT_F_TOKEN:
			return {...state, current_f_token: action.payload};

		case pro_action_types.SET_CURRENT_U_TOKEN:
			return {...state, current_u_token: action.payload};

		case pro_action_types.SET_CURRENT_MATURITY_DATE:
			return {...state, current_maturity_date: action.payload};

		case pro_action_types.CLEAR_STATE:
			return initial_state;

		case pro_action_types.SET_OPTIONS_PERIOD:
			return {...state, options_period: action.payload};

		default:
			return state;
	}
}