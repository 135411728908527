import { combineReducers } from "redux";

import { app_reducer } from "./app/app_reducer";
import { pro_reducer } from "./pro/pro_reducer";
import { basic_reducer } from './basic/basic_reducer';
import { AM_reducer } from "./approve_modal/AM_reducer";
import { MOM_reducer } from "./make_order_modal/MOM_reducer";
import { OBM_reducer } from "./order_book_modal/OBM_reducer";
import { RUBM_reducer } from "./request_uncreate_borrow_modal/RUBM_reducer";
import { IM_reducer } from "./info_modal/IM_reducer";
import { chromia_reducer } from "./chromia/chromia_reducer";
import { TOM_reducer } from "./take_order_modal/TOM_reducer";
import { UIReducer } from "./UI/UIReducer";

export const root_reducer = combineReducers({
	app: app_reducer,
	chromia: chromia_reducer,
	pro: pro_reducer,
	basic: basic_reducer,
	approve_modal: AM_reducer,
	order_book_modal: OBM_reducer,
	make_order_modal: MOM_reducer,
	take_order_modal: TOM_reducer,
	request_uncreate_borrow_modal: RUBM_reducer,
	info_modal: IM_reducer,
	UI: UIReducer
});