import { maturity_date } from "../../interfaces"
import { Dispatch } from "../store"
import { basic_action_types } from "./basic_types"

export function set_basic_step(step: number) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: basic_action_types.SET_CURRENT_STEP,
			payload: step
		})
	}
}

export function set_basic_f_token(token: string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: basic_action_types.SET_CURRENT_F_TOKEN,
			payload: token
		})
	}
}

export function set_basic_u_token(token: string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: basic_action_types.SET_CURRENT_U_TOKEN,
			payload: token
		})
	}
}

export function set_basic_direction(direction: 'up' | 'down') {
	return (dispatch : Dispatch) => {
		dispatch({
			type: basic_action_types.SET_CURRENT_DIRECTION,
			payload: direction
		})
	}
}

export function set_basic_maturity_date(maturity_date: maturity_date) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: basic_action_types.SET_CURRENT_MATURITY_DATE,
			payload: maturity_date
		})
	}
}

export function set_basic_strike(strike: number) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: basic_action_types.SET_CURRENT_STRIKE,
			payload: strike
		})
	}
}

export function step_back() {
	return (dispatch : Dispatch) => {
		dispatch({
			type: basic_action_types.STEP_BACK,
		})
	}
}