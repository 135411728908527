import { asset_balance, iFullfilled_account_details } from "../../chromia_interfaces"
import { Dispatch } from "../store"
import { chromia_action_types } from "./chromia_types"

export function set_chromia_account_details(account_details: iFullfilled_account_details) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: chromia_action_types.SET_ACCOUNT_DETAILS,
			payload: account_details
		})
	}
}

export function set_asset_balance(balance: asset_balance) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: chromia_action_types.SET_ASSET_BALANCE,
			payload: balance
		})
	}
}