import { Dispatch } from "../store"
import { pro_action_types } from "./pro_types"

export function set_pro_f_token (token: string | null) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: pro_action_types.SET_CURRENT_F_TOKEN,
			payload: token
		})
	}
}

export function set_pro_u_token (token: string | null) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: pro_action_types.SET_CURRENT_U_TOKEN,
			payload: token
		})
	}
}

export function set_pro_maturity_date(date:{ts: number, date: string} | null) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: pro_action_types.SET_CURRENT_MATURITY_DATE,
			payload: date
		})
	}
}

export function clear_pro_state() {
	return (dispatch : Dispatch) => {
		dispatch({
			type: pro_action_types.CLEAR_STATE,
		})
	}
}

export function set_options_period_pro(period: 'daily' | 'weekly') {
	return (dispatch : Dispatch) => {
		dispatch({
			type: pro_action_types.SET_OPTIONS_PERIOD,
			payload: period
		})
	}
}