import React, { useEffect, useState } from "react";

import { chain_ID } from "../../../settings";
import { useStoreDispatch, use_store_selector } from "../../../store/store";

/*
	Can be some problems if user will choose metamask, then will disconnect and then will choose not metamask (not sure)
*/

const ProviderChangesHandler: React.FC = () => {

	const { web3_provider } = use_store_selector( store => store.app );
	const { disconnect_app } = useStoreDispatch();

	const [is_listener_set, set_is_listener_set] = useState(false);

	useEffect(()=>{
		if (!is_listener_set && web3_provider){

			web3_provider.on && web3_provider.on("disconnect", (code: number, reason: string) => {	// listening disconnects from wallet_apps
				// console.log('code: ', code, '\nreason: ', reason);
				disconnect_app();
			});

			web3_provider.on && web3_provider.on('chainChanged', (chainID: string) =>{
				// console.log('chain changed to: ', chainID);
				// setTimeout(() => {
					if (chainID !== chain_ID){ // change to '0x38' for prod & '0x61' for test
						disconnect_app();
					}
				// }, 0);
			});

			web3_provider.on && web3_provider.on('accountsChanged', () =>{
				// console.log('account changed');
				window.location.reload();
				// disconnect_app();
			});
			set_is_listener_set(true);
		}
		// eslint-disable-next-line
	}, [web3_provider])


	return null;
}

export default ProviderChangesHandler;