import { iApprove_modal_state } from "../../interfaces"
import { AM_action, AM_action_types } from "./AM_types";

const initial_state: iApprove_modal_state = {
	modal_id: 0,
	approve_token: undefined,
	approve_amount: '',
	approve_promise_fork: undefined,
	is_option_blocked: false,
}

export const AM_reducer = ( state = initial_state, action: AM_action ) : iApprove_modal_state => {
	switch (action.type) {

		case AM_action_types.SET_APPROVE_TOKEN:
			return {...state, approve_token: action.payload}

		case AM_action_types.SET_APPROVE_AMOUNT:
			return {...state, approve_amount: action.payload}

		case AM_action_types.CALL_APPROVE:
			return {...state,
					approve_token: action.payload.approve_token,
					approve_amount: action.payload.approve_amount,
					is_option_blocked: action.payload.is_option_blocked? action.payload.is_option_blocked : false,
					approve_promise_fork: action.payload.approve_promise_fork,
				}

		case AM_action_types.CLOSE_APPROVE_MODAL:
			return {...initial_state, modal_id: state.modal_id+1,};

		default:
			return state;
	}
}