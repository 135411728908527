import { iApp_state } from "../../interfaces";
import { app_action, app_action_types} from "./app_types";

const initial_state: iApp_state = {
	client_addr: null,
	config: null,
	api: null,
	EOPT_contract: null,
	tokens: [],
	system_timestamp: null,
	web3_provider: null,
	opened_windows: [],
	is_pro: false,
	tokens_prices: [],
	current_options: [],
	client_balances: [],
	client_orders: [],
	option_amount_decimals: 3,
	price_decimals: 2,
	dark_mode: true,
	client_history: {
		is_updating: false,
		recordings: []
	},
	all_options: [],
	create_option_modal_id: 0,
	options_period: 'all',
	KasAccessToken: null,
}

export const app_reducer = ( state = initial_state, action: app_action) : iApp_state => {
	switch (action.type){

		case app_action_types.SET_CLIENT_ADDR:
			return {...state, client_addr: action.payload};

		case app_action_types.SET_CONFIG:
			return {...state, config: action.payload};

		case app_action_types.SET_API:
			return {...state, api: action.payload};

		case app_action_types.SET_EOPT_CONTRACT:
			return {...state, EOPT_contract: action.payload};

		case app_action_types.SET_TOKEN:
			// maybe add check if contract already exists
			let new_tokens = state.tokens.slice();
			new_tokens.push(action.payload);
			return {...state, tokens: new_tokens};

		case app_action_types.SET_SYSTEM_TIMESTAMP:
			return {...state, system_timestamp: action.payload};

		case app_action_types.SET_WEB3_PROVIDER:
			return {...state, web3_provider: action.payload};

		case app_action_types.CLEAR_APP_STATE:
			return {
				...initial_state,
				is_pro: state.is_pro,
				dark_mode: state.dark_mode,
				config: state.config,	// otherwise causes errors
				api: state.api
			};

		case app_action_types.SET_IS_PRO:
			return {...state, is_pro: action.payload};

		case app_action_types.SET_TOKEN_PRICE:
			let new_token_prices = state.tokens_prices.slice();
			let token_price = new_token_prices.find( price => price.currency === action.payload.currency);
			if (token_price){
				token_price.price = action.payload.price;
			} else {
				new_token_prices.push(action.payload);
			}
			return {...state, tokens_prices: new_token_prices};

		case app_action_types.SET_CURRENT_OPTIONS:
			return {...state, current_options: action.payload};

		case app_action_types.SET_CLIENT_BALANCES:
			return {...state, client_balances: action.payload};

		case app_action_types.SET_CLIENT_ORDERS:
			return {...state, client_orders: action.payload};

		case app_action_types.SET_DARK_MODE:
			return {...state, dark_mode: action.payload};

		case app_action_types.SET_IS_HISTORY_UPDATING:
			return {...state, client_history: {is_updating: action.payload, recordings: state.client_history.recordings} };

		case app_action_types.SET_HISTORY_RECORDINGS:
			return {...state, client_history: {is_updating: false, recordings: action.payload} };

		case app_action_types.SET_ALL_OPTIONS:
			return {...state, all_options: action.payload};

		case app_action_types.OPEN_NEW_WINDOW:
			let increased_open_windows = state.opened_windows.slice();
			increased_open_windows.push(action.payload);
			return {...state, opened_windows: increased_open_windows};

		case app_action_types.CLOSE_CHOOSEN_WINDOW:
			let open_windows_choosen = state.opened_windows.slice();
			let index = open_windows_choosen.indexOf(action.payload);
			if (index >= 0){
				open_windows_choosen.splice(index, 1);
			}
			return {...state, opened_windows: open_windows_choosen};

		case app_action_types.CLOSE_CREATE_OPTION_MODAL:
			return {...state, create_option_modal_id: state.create_option_modal_id+1};

		case app_action_types.SET_OPTIONS_PERIOD:
			return {...state, options_period: action.payload};

		case app_action_types.UPDATE_STRIKES:
			if (state.config){
				let config_copy = {...state.config};
				config_copy.strikes = action.payload;
				return {...state, config: config_copy};
			}
			return state;

		case app_action_types.SET_KAS_ACCESS_TOKEN:
			return {...state, KasAccessToken: action.payload}

		default: return state;
	}
}