export enum obm_action_types {
	CALL_MODAL = 'OBM/CALL_MODAL',
	CLOSE_MODAL = 'OBM/CLOSE_MODAL',
	SET_IS_BUTTONS_BLOCKED = 'OBM/SET_IS_BUTTONS_BLOCKED',
}

export type obm_action =
	call_order_book_modal_action |
	close_order_book_modal_action |
	set_is_buttons_blocked_action
;

interface call_order_book_modal_action {
	type: obm_action_types.CALL_MODAL,
	payload: number | string
}

interface close_order_book_modal_action {
	type: obm_action_types.CLOSE_MODAL
}


interface set_is_buttons_blocked_action {
	type: obm_action_types.SET_IS_BUTTONS_BLOCKED,
	payload: boolean
}