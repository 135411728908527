import { iRequest_uncreate_borrow_modal_state } from "../../interfaces"
import { RUBM_action, RUBM_action_types } from "./RUBM_types";

const initial_state: iRequest_uncreate_borrow_modal_state = {
	modal_id: 0,
	type: null,
	balance_opt_id: null,
}

export const RUBM_reducer = ( state = initial_state, action: RUBM_action ) : iRequest_uncreate_borrow_modal_state => {
	switch (action.type) {
		case RUBM_action_types.CLOSE_MODAL:
			return {...initial_state, modal_id: state.modal_id+1};

		case RUBM_action_types.CALL_MODAL:
			return {
				...state,
				type: action.payload.type,
				balance_opt_id: action.payload.balance_opt_id
			};

		default:
			return state;
	}
}