export enum MOM_action_types {
	CLOSE_MODAL = 'MOM/CLOSE_MODAL',
	CALL_CREATE_MODAL = 'MOM/CALL_CREATE_MODAL',
	CALL_MODIFY_MODAL = 'MOM/CALL_MODIFY_MODAL',
}

export type MOM_action =
	close_modal_action |
	call_create_modal_action |
	call_modify_modal_action
;

interface close_modal_action {
	type: MOM_action_types.CLOSE_MODAL
}

interface call_create_modal_action {
	type: MOM_action_types.CALL_CREATE_MODAL,
	payload: number | string
}

interface call_modify_modal_action {
	type: MOM_action_types.CALL_MODIFY_MODAL,
	payload: {
		opt_id: number | string,
		order: {id: number | string, is_sell: boolean}
	}
}