export enum RUBM_action_types {
	CLOSE_MODAL = 'RUBM/CLOSE_MODAL',
	CALL_MODAL = 'RUBM/CALL_MODAL',
}

export type RUBM_action =
	clear_state_action |
	call_modal_action
;

interface clear_state_action {
	type: RUBM_action_types.CLOSE_MODAL
}

interface call_modal_action {
	type: RUBM_action_types.CALL_MODAL,
	payload: {
		type: 'request' | 'uncreate' | 'borrow',
		balance_opt_id: number | string
	}
}