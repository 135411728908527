import React, { useEffect } from "react";

import { useStoreDispatch, use_store_selector } from "../../../store/store";


const ColorModeHandler: React.FC = () => {

	const { dark_mode } = use_store_selector( store => store.app );
	const { set_dark_mode, set_is_pro } = useStoreDispatch();

	useEffect(()=>{     // eopt mode init
		if (localStorage.getItem('eopt_mode') === 'pro'){	// 'pro' | 'basic' | null
			set_is_pro(true);
		} else {
			set_is_pro(false);
		}
		// eslint-disable-next-line
	}, [])

	useEffect(()=>{     // color mode init
		let color_mode = localStorage.getItem('eopt_color_mode');	// 'dark' | 'light' | null
		if ( color_mode === 'dark'){
			set_dark_mode(true);
		} else {
			if (color_mode === null){
				turn_dark_mode_off();
			}
			set_dark_mode(false);
		}
		// eslint-disable-next-line
	}, [])

	useEffect(()=>{     // color mode listener
		if (dark_mode){
			turn_dark_mode_on();
		} else {
			turn_dark_mode_off();
		}
	}, [dark_mode])

	function turn_dark_mode_on() {
		let body = document.getElementById('body');
		if (body){
			body.className = 'dark_mode';
			localStorage.setItem('eopt_color_mode', 'dark');
		}
	}

	function turn_dark_mode_off() {
		let body = document.getElementById('body');
		if (body){
			body.className = 'light_mode';
			localStorage.setItem('eopt_color_mode', 'light');
		}
	}


	return null;
}

export default ColorModeHandler;