// export const settings_api = 'https://dnt.techno-content.com/eopt_api_for_rpe_tn';
// export const settings_api_2 = 'https://dnt.techno-content.com/eopt_api_for_rpe_tn';

// export const settings_api = 'https://ovh3.techno-content.com:33443/eopt_api_v2';    // prod main
// export const settings_api_2 = 'https://ovh9.techno-content.com/eopt_api_v2';    // prod reserv

export const settings_api = 'https://dnt.techno-content.com/eopt_api_v2_tn_for_hedget';   // chromia back
// export const settings_api = 'https://ovh9.techno-content.com/eopt_chromia_api';   // chromia back
export const settings_api_2 = 'https://dnt.techno-content.com/eopt_api_v2_tn_for_hedget';   // chromia back
// export const settings_api_2 = 'https://ovh9.techno-content.com/eopt_chromia_api';   // chromia back

// export const basename = '/eopt_rpe_sbd/';
// export const basename = '/exchange/bscbeta5/';
// export const basename = '/eopt_chr/';
export const basename = '/dapp/';
// export const basename = '/exchange/bscbeta5a/';
// export const basename = '/exchange/chr_beta1/';

export const chain_ID: '0x38' | '0x61' = '0x61';    // 0x38 - mn, 0x61 - tn
export const enable_settings_page: boolean = true;
export const show_redux: boolean = false;   // set false in prod
export const version: string = 'v1.8.1';
export const disable_UI_blocikng: boolean = false;      // true for exclusive version
export const get_api_balancer_on: boolean = false;
export const is_debug_signer: boolean = false;       // false in prod
export const is_static_time: boolean = true;       // false in prod

export const blockchain: 'binance' | 'chromia' = 'chromia';


////////////    CHROMIA    ////////////
/* export const chromia_bc = {
	// node_api_url: 'https://hedget.com/demo/node/',
	node_api_url: 'https://chromia.techno-content.com:10443/bc/',
	rid: '550A5ABBF7A8EE4B44EE42E736392AE69F0982639AC31AE1905154C26C99B27E',
	auth_url: 'https://hedget.com/demo/auth',  // their
	// auth_url: 'http://192.168.50.83/auth',    // our
} */

// export const chromiaBackendUrl = 'https://chromia.techno-content.com:10443/dex_backend_tn';	// local registration
export const chromiaBackendUrl = 'https://chromia.techno-content.com:10443/dex_backend_chr_tn';	// devnet registration

export const chromiaBlockchainRID = '4AEB5DE8F71A6D6614CCAC582B45931A8A2FB94440810945132EEE3BD231A688';	// quest net
// export const chromiaBlockchainRID = 'F048F5E8BAD50DB9B04D22E245FB51F0D0E5874F8AFF9FBE050437947712CE71';	// dev net
// export const chromiaBlockchainRID = '8AE48FB9662A3DA58B1E5281BEBE1B3BF5AE1B0D1F59A9867DB8D5DC2971D24D';	// local

export const chromiaBlockchainUrl = [	// quest
	"https://node0.questnet.chromia.dev:7740",
	"https://node1.questnet.chromia.dev:7740",
	"https://node2.questnet.chromia.dev:7740",
	"https://node3.questnet.chromia.dev:7740"
]
/* export const chromiaBlockchainUrl = [	// dev net
	"https://node4.devnet1.chromia.dev:7740",
	"https://node5.devnet1.chromia.dev:7740",
	"https://node6.devnet1.chromia.dev:7740"
	// "https://node7.devnet1.chromia.dev:7740",
]; */
// export const chromiaBlockchainUrl = "http://chromia.techno-content.com:7740";	// local

export const KasUrl = 'https://dnt.techno-content.com/front_kas/';
export const projectName = 'EOPT_CHR'
export const isProduction = true;