import { iInfo_modal_state } from "../../interfaces";
import { IM_action, IM_action_types } from "./IM_types";

const initial_state: iInfo_modal_state = {
	modal_id: 0,
	message: null,
}

export const IM_reducer = ( state = initial_state, action: IM_action ) : iInfo_modal_state => {
	switch (action.type) {
		case IM_action_types.CLOSE_MODAL:
			return {...initial_state, modal_id: state.modal_id+1};

		case IM_action_types.CALL_INFO_MODAL:
			return {...state, message: action.payload};

		default:
			return state;
	}
}