// @ts-ignore
import { createClient, gtxClient as pc_gtx_client , IClient, restClient as pc_rest_client } from 'postchain-client';
// import { KeyPair } from 'ft3-lib';
// import { v4 as uuidv4 } from 'uuid';

// import { chromia_bc } from '../../settings';
import { iFullfilled_account_details, operation_names, postchain_gtx_client, postchain_rest_client } from '../../chromia_interfaces';
import { store } from '../../store/store';
import { chromia_action_types } from '../../store/chromia/chromia_types';
import { chromiaBlockchainRID, chromiaBlockchainUrl } from '../../settings';

export const getChrClient = async (): Promise<IClient> => {
	const { chrClient } = store.getState().chromia;
	if (chrClient) return chrClient;

	/* const response = await fetch(`${chromia_bc.node_api_url}brid/iid_0`);
	if (!response.ok) throw new Error();
	const rid = await response.text(); */

	const newChrClient = await createClient({
		nodeUrlPool: chromiaBlockchainUrl,
		blockchainRid: chromiaBlockchainRID
	});

	store.dispatch({
		type: chromia_action_types.SET_CHR_CLIENT,
		payload: newChrClient
	})

	return newChrClient;
}

/* export const rest_client = (pc_rest_client as postchain_rest_client).createRestClient(chromia_bc.node_api_url, chromia_bc.rid);

export const gtx_client = create_gtx_client([]);

export function create_gtx_client(operationNames: string[]) {
	return (pc_gtx_client as postchain_gtx_client).createClient(rest_client, Buffer.from(chromia_bc.rid, 'hex'), operationNames);
} */

export const run_operation_as_user = async (
	// dispatch: (any: any) => void,
	operation_name: operation_names,
	args: any[]
) => {
	// dispatch(setOperationPending(true));

   /*  const { key_pair, account_id, descriptor } = store.getState().chromia;
	if (!key_pair || !account_id || !descriptor) throw new Error(
		'Error code: 22_0_0' +
		(key_pair? '' : '\nkey: -') +
		(account_id? '' : '\nid: -') +
		(descriptor? '' : '\ndescriptor: -')
	);

	let err = null;

	// try {
		const tx = gtx_client.newTransaction([key_pair.pubKey]);
		tx.addOperation(operation_name,
			Buffer.from(account_id, 'hex'),
		   descriptor,
			...args
			);
		tx.addOperation('nop', Math.random().toString());
		tx.sign(key_pair.privKey, key_pair.pubKey);
		await tx.postAndWaitConfirmation(); */
		// dispatch(setSuccess("Success"));
	// } catch (e) {
		// err = e;
		// console.log(e);
		// dispatch(setError("Error when running an operation..."));
	// }
	// dispatch(updateBalances());
	// dispatch(setOperationPending(false));
	// return err;
};

export class Transaction {
	private operations: Operation[] = [];

	private pub_key: Buffer | undefined;

	private priv_key: Buffer | undefined;

	public static create() {
		return new Transaction();
	}

	public add_operation(operation: Operation) {
		this.operations.push(operation);
		return this;
	}

	public add_nop() {
		// this.operations.push(new Operation('nop', uuidv4()));
		return this;
	}

	/* public sign(kp: KeyPair) {
		this.priv_key = kp.privKey;
		this.pub_key = kp.pubKey;
		return this;
	} */

	public confirm() {
		if (!this.priv_key || !this.pub_key) throw new Error(
			'Error code: 22_0_1' +
			(this.pub_key? '' : 'pub: -') +
			(this.priv_key? '' : 'priv: -')
		);
		/* const gtx = create_gtx_client([]);
		const req = gtx.newTransaction([this.pub_key]);
		this.operations.forEach((o) => req.addOperation(o.name, ...o.args));
		req.sign(this.priv_key, this.pub_key);
		return req.postAndWaitConfirmation(); */
	}
}

export class Operation {
	public name: operation_names;

	public args: (string | Buffer)[];

	constructor(name: operation_names, ...args: (string | Buffer)[]) {
		this.name = name;
		this.args = args;
	}
}

export async function get_stored_account_details(client_addr: string) {
// export async function get_stored_account_details(client_addr: string): Promise<iFullfilled_account_details | undefined> {
	/* const prefix = "hd_" + client_addr.slice(2) + "_";
	const key_pair = load_pk(prefix);
	const acc_id = localStorage[prefix + "account_id"];
	const desc = localStorage[prefix + "descriptor"]
	const account_iid_str = localStorage[prefix + "account_iid"];

	if (!key_pair || !acc_id || !desc || !account_iid_str) return;
	const stored_details = {
		key_pair: key_pair,
		account_id: acc_id,
		descriptor: desc,
		client_addr: client_addr,
		account_iid: parseInt(account_iid_str)
	}

	const is_decriptor_valid = await gtx_client.query('is_auth_descriptor_valid', {
		eth_address: client_addr,
		auth_descriptor_id: desc
	});
	if (!Boolean(is_decriptor_valid)){
		clear_account_details(client_addr);
		return;
	} else {
		return stored_details;
	} */
}

/* function load_pk(prefix: string): KeyPair | undefined {
	const key = prefix + "privkey";
	if (key in localStorage) {
		const pk = localStorage[key];
		return new KeyPair(pk);
	}
} */

export function store_account_details(details: iFullfilled_account_details) {
	/* const prefix = "hd_" + details.client_addr.slice(2) + "_";
	localStorage.setItem(prefix + "account_id", details.account_id);
	localStorage.setItem(prefix + "account_iid", details.account_iid.toString() );
	localStorage.setItem(prefix + "descriptor", details.descriptor);
	localStorage.setItem(prefix + "privkey", details.key_pair.privKey.toString('hex')); */
	// localStorage.setItem('CHROMIA_CONNECT_CACHED_DETAILS', details.client_addr.slice(2));
}

function clear_account_details(client_addr: string) {
	const prefix = "hd_" + client_addr.slice(2) + "_";
	localStorage.removeItem(prefix + "account_id");
	localStorage.removeItem(prefix + "account_iid");
	localStorage.removeItem(prefix + "descriptor");
	localStorage.removeItem(prefix + "privkey");
}