import { iTake_order_modal_state } from "../../interfaces";
import { TOM_action, TOM_action_types } from "./TOM_types";

const initial_state: iTake_order_modal_state = {
	modal_id: 0,
	opt_id: null,
	is_sell: null,
}

export const TOM_reducer = ( state = initial_state, action: TOM_action ) : iTake_order_modal_state => {
	switch (action.type) {
		case TOM_action_types.CLOSE_MODAL:
			return {...initial_state, modal_id: state.modal_id+1};


		case TOM_action_types.CALL_MODAL:
			return {
				...state,
				opt_id: action.payload.opt_id,
				is_sell: action.payload.is_sell,
			};

		default:
			return state;
	}
}