import { calculate_order_current_price, get_token_currency_by_addr } from '../../common_funcs';
import { iOption, iOrder, iOrder_response } from '../../interfaces';
import { blockchain } from '../../settings';
import { store } from '../../store/store';
import { Option } from './option';


export class Order implements iOrder {
	ord_id: number;
	ord_type: boolean;   // true => sell, false => buy
	opt_id: number;
	owner: string
	amount: string;
	ref_price: string;
	delta: string;
	ord_price: string;
	min_max_price: string;
	current_price: string;
	option: iOption | undefined = undefined;

	constructor(order_response: iOrder_response, option_u_tok? : string){
		this.ord_id = order_response.ord_id;
		this.ord_type = order_response.ord_type;
		this.opt_id = order_response.opt_id;
		this.owner = order_response.owner;
		this.amount = order_response.amount;
		this.ref_price = order_response.ref_price;
		this.delta = order_response.delta;
		this.ord_price = order_response.ord_price;
		this.min_max_price = order_response.min_max_price;

		// current price
		if (order_response.option || option_u_tok){  // can calculate real price

			switch (blockchain) {
				case 'binance':
					let curr_u_tok = order_response.option? get_token_currency_by_addr(order_response.option.u_token) : option_u_tok;
					let curr_price = store.getState().app.tokens_prices.find(price => price.currency === curr_u_tok);

					if(!curr_price || !curr_price.price) throw new Error(`Error code: 2_1\nu_tok: ${curr_u_tok}`);

					this.current_price = calculate_order_current_price(order_response, curr_price.price);
					break;

				case 'chromia':
					this.current_price = order_response.ord_price;
					break;

				default:
					throw new Error('Error code: 2_2\nBC: ' + blockchain);
			}
		} else {
			throw new Error(`Error code: 2_0`);
		}

		// option
		if (order_response.option){
			this.option = new Option(order_response.option);
		}
	}
}