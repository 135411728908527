import React, { useEffect } from "react";

import { store } from "../../../store/store";


const EscapeHandler: React.FC = () => {

	useEffect(()=>{
		document.addEventListener('keydown', escape_handler);
		// eslint-disable-next-line
	},[])

	function close_last_window() {
		let windows = store.getState().app.opened_windows;
		if (windows.length > 0){
			let last_window = windows[windows.length -1];
			document.dispatchEvent(new Event(`close_${last_window}`));
		}
	}

	function escape_handler(e: KeyboardEvent) {
		if (e.key === 'Escape'){
			setTimeout(() => {
				close_last_window();
			});
		}
	};


	return null;
}

export default EscapeHandler;