import { BN_to_val, get_human_date, get_option_info } from "../../common_funcs";
import { history_recording_names, iHistory_recording, iHistory_response_recordings } from "../../interfaces";


export class History_recording implements iHistory_recording {
	block_num: number = NaN;
	log_idx: number = NaN;
	id: number = NaN;
	name: history_recording_names = '';
	block_hash: string = '';
	time: number = NaN;   // ts
	tr_hash: string = '';
	data: string = '';

	human_time: string= 'invalid date'; // DD mmm YY, hh:mm
	action: string = '';
	details: string[] = [];


	constructor(history_response_recording: iHistory_response_recordings){
		this.block_num = history_response_recording.block_num;
		this.log_idx = history_response_recording.log_idx;
		this.id = history_response_recording.id;
		this.name = history_response_recording.name;
		this.block_hash = history_response_recording.block_hash;
		this.time = history_response_recording.time;
		this.tr_hash = history_response_recording.tr_hash;
		this.data = history_response_recording.data;

		// human time
		this.human_time = get_human_date(history_response_recording.time, 'DD mmm YY, hh:mm');

		// action & details
		let cutted_data: string[] = history_response_recording.data.split(',');

		switch (history_response_recording.name) {
			case 'TakeBuyOrder':
				this.details = [
					get_option_info(+cutted_data[2]) || 'unknown option',
					`Amount: ${BN_to_val(cutted_data[4])},
					Price: ${BN_to_val(cutted_data[5])}`
				];
				this.action = 'Take Buy Order';
				break;

			case 'TakeSellOrder':
				this.details = [
					get_option_info(+cutted_data[2]) || 'unknown option',
					`Amount: ${BN_to_val(cutted_data[4])},
					Price: ${BN_to_val(cutted_data[5])}`
				];
				this.action = 'Take Sell Order';
				break;

			case 'CreateOption':
				this.details = [
					get_option_info(+cutted_data[1]) || 'unknown option',
					`Amount: ${BN_to_val(cutted_data[2])}`
				];
				this.action = 'Create Option';
				break;

			case 'MakeSellOrder':
				this.details = [
					get_option_info(+cutted_data[1]) || 'unknown option',
					`Amount: ${BN_to_val(cutted_data[3])},
					Ref price: ${BN_to_val(cutted_data[4])},
					Delta: ${BN_to_val(cutted_data[5])},
					Order price: ${BN_to_val(cutted_data[6])},
					Min price: ${BN_to_val(cutted_data[7])}`
				];
					// версия, которая не отображает поле при нуле
					//${ +BN_to_val(cutted_data[4])? `Ref price: ${BN_to_val(cutted_data[4])},` : ''}
					//${ +BN_to_val(cutted_data[5])? `Delta: ${BN_to_val(cutted_data[5])},` : ''}
				this.action = 'Make Sell Order';
				break;

			case 'FinalizeOptionExercise':
				this.details = [
					get_option_info(+cutted_data[1]) || 'unknown option',
					`Amount: ${BN_to_val(cutted_data[2])}`
				];
				this.action = 'Finalize Option Exercise';
				break;

			case 'WithdrawOption':
				this.details = [
					get_option_info(+cutted_data[1]) || 'unknown option',
					`Call amount: ${BN_to_val(cutted_data[2])},
					Exec amount: ${BN_to_val(cutted_data[3])}`];
				this.action = 'Withdraw Option';
				break;

			case 'RequestOptionExercise':
				if (+BN_to_val(cutted_data[2]) === 0){
					this.details = [
						get_option_info(+cutted_data[1]) || 'unknown option'
					];
					this.action = 'Cancel Option Exercise'
				} else {
					this.details = [
						get_option_info(+cutted_data[1]) || 'unknown option',
						`Amount: ${BN_to_val(cutted_data[2])}`
					];
					this.action = 'Request Option Exercise';
				}
				break;

			case 'AcceptTsReq':
				this.details = [
					get_option_info(+cutted_data[0]) || 'unknown option'
				];
				this.action = 'Accept TS Request';
				break;

			case 'MakeBuyOrder':
				this.details = [
					get_option_info(+cutted_data[1]) || 'unknown option',
					`Amount: ${BN_to_val(cutted_data[3])},
					Ref price: ${BN_to_val(cutted_data[4])},
					Delta: ${BN_to_val(cutted_data[5])},
					Order price: ${BN_to_val(cutted_data[6])},
					Min price: ${BN_to_val(cutted_data[7])}`
				];
				this.action = 'Make Buy Order';
				break;

			case 'NewTsReq':
				this.details = [
					get_option_info(+cutted_data[0], 'Old option: ') || 'unknown option',
					get_option_info(+cutted_data[2], 'New option: ') || 'unknown option',
					`Target: ${BN_to_val(cutted_data[4])},
					Amount: ${BN_to_val(cutted_data[5])},
					Return percent: ${BN_to_val(cutted_data[6])},
					Price: ${BN_to_val(cutted_data[7])},
					Ref price: ${BN_to_val(cutted_data[8])},
					Delta: ${BN_to_val(cutted_data[9])},
					Min price: ${BN_to_val(cutted_data[10])}`
				];
				this.action = 'New TS Request';
				break;

			case 'ChangeBuyOrder':
				if (+BN_to_val(cutted_data[3]) === 0){
					this.details = [
						get_option_info(+cutted_data[1]) || 'unknown option'
					];
					this.action = 'Cancel Buy Order'
				} else {
					this.details = [
						get_option_info(+cutted_data[1]) || 'unknown option',
						`Amount: ${BN_to_val(cutted_data[3])},
						Ref price: ${BN_to_val(cutted_data[4])},
						Delta: ${BN_to_val(cutted_data[5])},
						Order price: ${BN_to_val(cutted_data[6])},
						Min price: ${BN_to_val(cutted_data[7])}`
					];
					this.action = 'Change Buy Order'
				}
				break;

			case 'ChangeSellOrder':
				if (+BN_to_val(cutted_data[3]) === 0){
					this.details = [
						get_option_info(+cutted_data[1]) || 'unknown option'
					];
					this.action = 'Cancel Sell Order'
				} else {
					this.details = [
						get_option_info(+cutted_data[1]) || 'unknown option',
						`Amount: ${BN_to_val(cutted_data[3])},
						Ref price: ${BN_to_val(cutted_data[4])},
						Delta: ${BN_to_val(cutted_data[5])},
						Order price: ${BN_to_val(cutted_data[6])},
						Min price: ${BN_to_val(cutted_data[7])}`
					];
					this.action = 'Change Sell Order'
				}
				break;

			case 'PushTsReq':
				this.details = [
					get_option_info(+cutted_data[0]) || 'unknown option',
					`Option amount: ${BN_to_val(cutted_data[2])},
					Borrow amount: ${BN_to_val(cutted_data[3])}`
				];
				this.action = 'Push TS Req';
				break;

			case 'UncreateOption':
				this.details = [
					get_option_info(+cutted_data[1]) || 'unknown option',
					`Amount: ${BN_to_val(cutted_data[2])}`
				];
				this.action = 'Uncreate Option';
				break;

			case 'ChangeBorrow':
				this.details = [
					get_option_info(+cutted_data[1]) || 'unknown option',
					`Amount: ${BN_to_val(cutted_data[2])}`
				];
				this.action = 'Change Borrow';
				break;

			/*case '':
				this.details = [``]
				break;*/

			default:
				this.details = [];
				break;
		}
	}
}