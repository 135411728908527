import { Dispatch } from "../store"

import { UIActionTypes } from "./UITypes"

export const clearUIState = () => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: UIActionTypes.CLEAR_STATE,
		})
	}
}

export const setInited = () => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: UIActionTypes.SET_INITED,
		})
	}
}

export const setIsChrRegistered = (state: boolean) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: UIActionTypes.SET_IS_CHR_REGISTERED,
			payload: state
		})
	}
}