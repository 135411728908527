import { get_token_currency_by_addr } from "../../common_funcs";
import { iOption, iOption_response, iOrder } from "../../interfaces";
import { Order } from "./order";


export class Option implements iOption{
	opt_id: number | string;
	f_tok_curr: string;
	f_tok_adr: string;
	u_tok_curr: string;
	u_tok_adr: string;
	option_type: boolean; // true => call, false => put
	option_type_text: 'Put' | 'Call';
	strike: string;
	// create_time: number;
	maturity_time: number;
	period: "daily" | "weekly";
	maturity_price: string;
	orders_buy: iOrder[];
	orders_sell: iOrder[];


	constructor(response_option: iOption_response){
		this.opt_id = response_option.opt_id;

		this.f_tok_adr = response_option.f_token;
		this.u_tok_adr = response_option.u_token;

		let temp_u_tok = get_token_currency_by_addr(response_option.u_token);

		this.f_tok_curr = get_token_currency_by_addr(response_option.f_token);
		this.u_tok_curr = temp_u_tok;

		this.option_type = response_option.call_type;
		this.option_type_text = response_option.call_type? 'Call' : 'Put';

		this.strike = response_option.strike;

		// this.create_time = response_option.create_time;
		this.maturity_time = response_option.maturity_time;

		this.maturity_price = response_option.maturity_price;

		let buy_orders: iOrder[] = [];
		let sell_orders: iOrder[] = [];
		response_option.orders && response_option.orders
			.map(order => order.ord_type
				? sell_orders.push(new Order(order, temp_u_tok))
				: buy_orders.push(new Order(order, temp_u_tok))
			)
		;
		// sorting (first order - best)
		buy_orders.sort( (a, b) => +b.current_price - +a.current_price );
		sell_orders.sort( (a, b) => +a.current_price - +b.current_price );

		this.orders_buy = buy_orders;
		this.orders_sell = sell_orders;

		// period
		if (response_option.maturity_time - response_option.create_time < 60*60*48){
			this.period = 'daily';
		} else {
			this.period = 'weekly';
		}
	}
}