import { iPromise_fork } from "../../interfaces";

export enum AM_action_types {
	SET_APPROVE_TOKEN = 'AM/SET_APPROVE_TOKEN',
	SET_APPROVE_AMOUNT = 'AM/SET_APPROVE_AMOUNT',
	CALL_APPROVE = 'AM/CALL_APPROVE',
	CLOSE_APPROVE_MODAL = 'AM/CLOSE_APPROVE_MODAL',
}

export type AM_action =
	set_approve_token_action |
	set_approve_amount_action |
	call_approve_action |
	close_approve_modal_action
;

interface set_approve_token_action {
	type: AM_action_types.SET_APPROVE_TOKEN,
	payload: string;
}

interface set_approve_amount_action {
	type: AM_action_types.SET_APPROVE_AMOUNT,
	payload: string
}

interface call_approve_action {
	type: AM_action_types.CALL_APPROVE,
	payload: {approve_token: string, approve_amount: string, approve_promise_fork: iPromise_fork, is_option_blocked?: boolean}
}

interface close_approve_modal_action {
	type: AM_action_types.CLOSE_APPROVE_MODAL
}