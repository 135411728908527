import { useEffect, useState } from "react";
import { modal_name } from "../interfaces";
import { use_store_selector } from "../store/store";

interface first_tabindex {
	position: 'first'
}
interface last_tabindex {
	position: 'last',
	modal_name: modal_name
}

export function useTabindex(props: first_tabindex | last_tabindex): boolean {
	const [is_tabinex, set_is_tabindex] = useState(false);
	const { opened_windows } = use_store_selector( store => store.app );

	useEffect(()=>{
		if (props.position === 'first'){
			set_is_tabindex( opened_windows.length === 0 );
		}
		if (props.position === 'last'){
			set_is_tabindex( opened_windows[opened_windows.length -1] === props.modal_name );
		}
		// eslint-disable-next-line
	}, [opened_windows]);

	return is_tabinex;
}