import { iBalance, iConfig, iConfig_strikes, iEOPT_contract, iHistory_recording, iOption, iOrder, iWeb3_provider, modal_name, token_info } from "../../interfaces";

export enum app_action_types {
	SET_CLIENT_ADDR = 'APP/SET_CLIENT_ADDR',
	SET_CONFIG = 'APP/SET_CONFIG',
	SET_API = 'APP/SET_API',
	SET_EOPT_CONTRACT = 'APP/SET_EOPT_CONTRACT',
	SET_TOKEN = 'APP/SET_TOKEN',
	SET_SYSTEM_TIMESTAMP = 'APP/SET_SYSTEM_TIMESTAMP',
	SET_WEB3_PROVIDER = 'APP/SET_WEB3_PROVIDER',
	CLEAR_APP_STATE = 'APP/CLEAR_STATE',
	SET_IS_PRO = 'APP/SET_IS_PRO',
	SET_TOKEN_PRICE = 'APP/SET_TOKEN_PRICE',
	SET_CURRENT_OPTIONS = 'APP/SET_CURRENT_OPTIONS',
	SET_CLIENT_BALANCES = 'APP/SET_CLIENT_BALANCES',
	SET_CLIENT_ORDERS = 'APP/SET_CLIENT_ORDERS',
	SET_DARK_MODE = 'APP/SET_DARK_MODE',
	SET_IS_HISTORY_UPDATING = 'APP/SET_IS_HISTORY_UPDATING',
	SET_HISTORY_RECORDINGS = 'APP/SET_HISTORY_RECORDINGS',
	SET_ALL_OPTIONS = 'APP/SET_ALL_OPTIONS',
	OPEN_NEW_WINDOW = 'APP/OPEN_NEW_WINDOW',
	CLOSE_CHOOSEN_WINDOW = 'APP/CLOSE_CHOOSEN_WINDOW',
	CLOSE_CREATE_OPTION_MODAL = 'APP/CLOSE_CREATE_OPTION_MODAL',
	SET_OPTIONS_PERIOD = 'APP/SET_OPTIONS_PERIOD',
	UPDATE_STRIKES = 'APP/UPDATE_STRIKES',
	SET_KAS_ACCESS_TOKEN = 'APP/SET_KAS_ACCESS_TOKEN',
}

export type app_action =
	set_client_addr_action |
	set_config_action |
	set_api_action |
	set_EOPT_contract_action |
	set_token_action |
	set_system_timestamp_action |
	set_web3_provider_action |
	clear_app_state_action |
	set_is_pro_action |
	set_token_price_action |
	set_current_options_action |
	set_client_balances_action |
	set_client_orders_action |
	set_dark_mode_action |
	set_is_history_updating_action |
	set_history_recordings_action |
	set_all_options_action |
	open_new_window_action |
	close_choosen_window_action |
	close_create_option_modal_action |
	set_options_period_action |
	update_strikes_action |
	set_kas_access_token_action
;

interface set_client_addr_action {
	type: app_action_types.SET_CLIENT_ADDR,
	payload: string | null;
}

interface set_config_action {
	type: app_action_types.SET_CONFIG,
	payload: iConfig
}

interface set_api_action {
	type: app_action_types.SET_API,
	payload: string
}

interface set_EOPT_contract_action {
	type: app_action_types.SET_EOPT_CONTRACT,
	payload: iEOPT_contract
}

interface set_token_action {
	type: app_action_types.SET_TOKEN,
	payload: token_info
}

interface set_system_timestamp_action {
	type: app_action_types.SET_SYSTEM_TIMESTAMP,
	payload: number
}

interface set_web3_provider_action {
	type: app_action_types.SET_WEB3_PROVIDER,
	payload: iWeb3_provider
}

interface clear_app_state_action {
	type: app_action_types.CLEAR_APP_STATE,
}

interface set_is_pro_action {
	type: app_action_types.SET_IS_PRO,
	payload: boolean
}

interface set_token_price_action {
	type: app_action_types.SET_TOKEN_PRICE,
	payload: {
		currency: string,
		price: number
	}
}

interface set_current_options_action {
	type: app_action_types.SET_CURRENT_OPTIONS,
	payload: iOption[]
}

interface set_client_balances_action {
	type: app_action_types.SET_CLIENT_BALANCES,
	payload: iBalance[]
}

interface set_client_orders_action {
	type: app_action_types.SET_CLIENT_ORDERS,
	payload: iOrder[]
}

interface set_dark_mode_action {
	type: app_action_types.SET_DARK_MODE,
	payload: boolean
}

interface set_is_history_updating_action {
	type: app_action_types.SET_IS_HISTORY_UPDATING,
	payload: boolean
}

interface set_history_recordings_action {
	type: app_action_types.SET_HISTORY_RECORDINGS,
	payload: iHistory_recording[]
}

interface set_all_options_action {
	type: app_action_types.SET_ALL_OPTIONS,
	payload: iOption[]
}

interface open_new_window_action {
	type: app_action_types.OPEN_NEW_WINDOW,
	payload: modal_name
}

interface close_choosen_window_action {
	type: app_action_types.CLOSE_CHOOSEN_WINDOW,
	payload: modal_name
}

interface close_create_option_modal_action {
	type: app_action_types.CLOSE_CREATE_OPTION_MODAL
}

interface set_options_period_action {
	type: app_action_types.SET_OPTIONS_PERIOD,
	payload: 'all' | 'daily' | 'weekly' | 'non-standart'
}

interface update_strikes_action {
	type: app_action_types.UPDATE_STRIKES,
	payload: iConfig_strikes
}

interface set_kas_access_token_action {
	type: app_action_types.SET_KAS_ACCESS_TOKEN,
	payload: string
}