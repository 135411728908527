import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useDisconnect } from 'wagmi';

import { add_close_modal_listener, remove_close_modal_listener } from '../../../common_funcs';
import { useStoreDispatch, use_store_selector } from '../../../store/store';
import { useTabindex } from '../../../hooks/useTabindex';

import './header.scss';

import Button from '../button/button';
import ColorModeTrigger from '../color_mode_trigger/color_mode_trigger';
import TradeModeTrigger from '../trade_mode_trigger/trade_mode_trigger';


const Header: React.FC = () => {

	const location = useLocation();
	const { disconnect: disconnectWallet } = useDisconnect();

	const { client_addr, is_pro } = use_store_selector( store => store.app );
	const { disconnect_app, open_new_window, close_choosen_window } = useStoreDispatch();

	const is_menu_opened = useTabindex({position: 'last', modal_name: 'mobile_menu'});
	const is_default_header_tabindex = useTabindex({position: 'first'});
	const [header_page, set_header_page] = useState<'portfolio' | 'options' | 'trade'>();

	useEffect(()=>{     // close_modal_listener
		let close_func = () => close_choosen_window('mobile_menu');   // because close_choosen_window have arguments
		add_close_modal_listener ('mobile_menu', close_func);
		return ()=>{
			remove_close_modal_listener('mobile_menu', close_func);
		}
		// eslint-disable-next-line
	}, [])

	useEffect(()=>{     // header_page_observer
		if (location.pathname.includes('/portfolio')){
			set_header_page('portfolio');
			return;
		}
		if (location.pathname.includes('/options')){
			set_header_page('options');
			return;
		}
		if (location.pathname.includes('/trade')){
			set_header_page('trade');
			return;
		}
		set_header_page(undefined);
	}, [location])

	function toggle_menu() {
		if (is_menu_opened){
			close_choosen_window('mobile_menu');
		} else {
			open_new_window('mobile_menu');
		}
	}

	const logout = () => {
		disconnectWallet();
		disconnect_app();
	}


	return (
		<>
			<div className={'Header' + (is_menu_opened? ' Header_mobile_fixed' : '')}>
				{/* <a
					href='https://hedget.com/'
					className={`Header_logo ${is_menu_opened? 'logo_opened' : ''}`}
					tabIndex={(is_default_header_tabindex || is_menu_opened)? 0 : -1}
				> </a> */}
				<div
					className={`Header_logo ${is_menu_opened? 'logo_opened' : ''}`}
					tabIndex={(is_default_header_tabindex || is_menu_opened)? 0 : -1}
				/>

				{header_page !== 'portfolio'?
					<Link
						className='Header_page'
						to='/portfolio'
						tabIndex={is_default_header_tabindex? 0 : -1}
					>
						Portfolio
					</Link>
				:
					<div className='Header_page_current'>Portfolio</div>
				}

				{is_pro?
					header_page !== 'options'?
						<Link
							className='Header_page'
							to='/options'
							tabIndex={is_default_header_tabindex? 0 : -1}
						>
							Options
						</Link>
					:
						<div className='Header_page_current'>Options</div>
				:
					<></>
				}

				{header_page !== 'trade'?
					<Link
						className='Header_page'
						to={is_pro? '/trade/pro' : '/trade/basic'}
						tabIndex={is_default_header_tabindex? 0 : -1}
					>
						Trade
					</Link>
				:
					<div className='Header_page_current'>Trade</div>
				}

				<div className='Header_color_trigger'>
					<ColorModeTrigger minimized is_tabindex={is_default_header_tabindex}/>
				</div>

				<div className='Header_account_block'>
					{client_addr?
						<>
							{client_addr.substring(0,4) + '...' + client_addr.substring(client_addr.length - 4)}
							<Button
								action={logout}
								color='green'
								height='25px'
								width='80px'
								tabindex={is_default_header_tabindex}
							>
								logout
							</Button>
						</>
					:
						`Account isn't\nconnected`
					}
				</div>

				<div
					className='Header_mobile_menu_button'
					>
					<svg
						onClick={toggle_menu}
						tabIndex={(is_default_header_tabindex || is_menu_opened)? 0 : undefined}
						onKeyDown={e => e.key === 'Enter' && toggle_menu()}
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						width="36"
						height="36"
						viewBox="0 0 24 24"
					>
						<path
							className={`menu_path ${is_menu_opened? 'menu_off' : 'menu_on'}`}
							d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"
						/>
						<path
							className={`close_path ${is_menu_opened? 'close_on' : 'menu_off'}`}
							d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"
						/>
					</svg>
				</div>

				<div className={`Header_mobile_menu ${is_menu_opened? 'mobile_menu_opened' : ''}`}>
					<div className='Header_mobile_menu_content'>
						<div className='mobile_menu_account_block'>
							{client_addr?
								<>
									<div className='mobile_menu_account_block_text'>
										{client_addr.substring(0,4) + '...' + client_addr.substring(client_addr.length - 4)}
									</div>
									<Button
										action={logout}
										color='green'
										height='30px'
										class_name='button_margin'
										tabindex={is_menu_opened}
									>
										logout
									</Button>
								</>
							:
								`Account isn't\nconnected`
							}
						</div>

						<div className='mobile_menu_links_block'>
							{header_page !== 'portfolio'?
								<Link
									className='Header_page_mobile'
									to='/portfolio'
									tabIndex={is_menu_opened? 0 : -1}
									onClick={toggle_menu}
								>
									Portfolio
								</Link>
							:
								<div className='Header_page_current_mobile'>Portfolio</div>
							}

							{is_pro?
								header_page !== 'options'?
									<Link
										className='Header_page_mobile'
										to='/options' tabIndex={is_menu_opened? 0 : -1}
										onClick={toggle_menu}
									>
										Options
									</Link>
								:
									<div className='Header_page_current_mobile'>Options</div>
							:
								<></>
							}

							{header_page !== 'trade'?
								<Link
									className='Header_page_mobile'
									to={is_pro? '/trade/pro' : '/trade/basic'}
									tabIndex={is_menu_opened? 0 : -1}
									onClick={toggle_menu}
								>
									Trade
								</Link>
							:
								<div className='Header_page_current_mobile'>Trade</div>
							}
						</div>

						<div className='mobile_menu_switchers'>
							<TradeModeTrigger is_tabindex={is_menu_opened} toggle_menu={toggle_menu}/>
							<div className="triggers_divider"/>
							<ColorModeTrigger is_tabindex={is_menu_opened}/>
						</div>
					</div>
				</div>
			</div>
			{is_menu_opened && <div className='Header_replacer'/>}
		</>
	)
}

export default Header;