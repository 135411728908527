import { iUIState } from "../../interfaces";
import { UIAction, UIActionTypes} from "./UITypes";

const initialState: iUIState = {
	isInited: false,
	isChrRegistered: true,
}

export const UIReducer = ( state = initialState, action: UIAction) : iUIState => {
	switch (action.type){

		case UIActionTypes.CLEAR_STATE:
			return initialState
		;

		case UIActionTypes.SET_INITED:
			return {...state, isInited: true}
		;

		case UIActionTypes.SET_IS_CHR_REGISTERED:
			return {...state, isChrRegistered: action.payload}
		;

		default: return state;
	}
}