import React from "react";
import { Link } from "react-router-dom";

import { use_store_selector } from "../../../store/store";

import './trade_mode_trigger.scss';

import pro_light from '../../../assets/pro_light.png';
import pro_dark from '../../../assets/pro_dark.png';
import basic_light from '../../../assets/basic_light.png';
import basic_dark from '../../../assets/basic_dark.png';


const TradeModeTrigger: React.FC <{is_tabindex?: boolean; toggle_menu?: () => void}> = ({is_tabindex, toggle_menu}) => {

	const { is_pro, dark_mode } = use_store_selector( store => store.app );


	return (
		<div className='Trade_mode_trigger'>
			<Link
				to='/trade/basic'
				className={is_pro? 'trigger_link' : 'trigger_link_active'}
				tabIndex={is_pro && is_tabindex? 0 : -1}
				onClick={() => toggle_menu && toggle_menu()}
			>
				{(!is_pro || !dark_mode)
					? <img src={basic_light} alt=' '/>
					: <img src={basic_dark} alt=' '/>
				}
				Basic
			</Link>
			<Link
				to='/trade/pro'
				className={is_pro? 'trigger_link_active' : 'trigger_link'}
				tabIndex={!is_pro && is_tabindex? 0 : -1}
				onClick={() => toggle_menu && toggle_menu()}
			>
				{(!dark_mode || is_pro)
					? <img src={pro_dark} alt=' '/>
					: <img src={pro_light} alt=' '/>
				}
				Pro
			</Link>
		</div>
	)
}

export default TradeModeTrigger;