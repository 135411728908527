export enum TOM_action_types {
	CLOSE_MODAL = 'TOM/CLOSE_MODAL',
	CALL_MODAL = 'TOM/CALL_CREATE_MODAL',
}

export type TOM_action =  
	close_modal_action |
	call_modal_action
;

interface close_modal_action {
	type: TOM_action_types.CLOSE_MODAL
}

interface call_modal_action {
	type: TOM_action_types.CALL_MODAL,
	payload: {
		opt_id: number | string,
		is_sell: boolean
	}
}