import React from 'react';

import './loader.scss';


const Loader: React.FC <{transparent?: boolean}> = ({transparent}) => {

	const style: React.CSSProperties = {
		backgroundColor: transparent? 'rgba(0, 0, 0, .3)' : undefined
	}

	return(
		<div className='loader'>
			<div style={style}>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	)
}

export default Loader;