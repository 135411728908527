import { app_action_types } from "../app/app_types"
import { Dispatch } from "../store"
import { TOM_action_types } from "./TOM_types"

export function close_take_order_modal() {
	return (dispatch : Dispatch) => {
		dispatch({
			type: TOM_action_types.CLOSE_MODAL
		});
		dispatch({
			type: app_action_types.CLOSE_CHOOSEN_WINDOW,
			payload: 'take_order'
		});
	}
}

export function call_take_order_modal(opt_id: number | string, is_sell: boolean) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: TOM_action_types.CALL_MODAL,
			payload: {
				opt_id,
				is_sell
			}
		});
		dispatch({
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: 'take_order'
		});
	}
}