import React, { PropsWithChildren } from 'react';

import './button.scss';

type hint_time = 'blocked' | 'loading' | 'default';

interface iButton_input{
	action? : () => void,
	on_enter_action? : () => void,
	width? : string | number,
	height? : string | number,
	hint? : string,
	tabindex? : boolean | number,
	blocked? : boolean,
	color? : 'green' | 'green_outline' | 'red' | 'red_outline' | 'blue' | 'blue_outline',
	font_size? : string | number,
	font_weight? : 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900, // | 'normal' | 'bold'
	class_name? : string,
	is_loading? : boolean,
	when_show_hint? : 'everytime' | hint_time | hint_time[];
}


const Button: React.FC <PropsWithChildren<iButton_input>> = ({
	action,
	on_enter_action,
	width,
	height,
	hint,
	tabindex,
	blocked,
	color = 'green',	// inital state (if not provided)
	font_size,
	font_weight,
	class_name,
	children,
	is_loading,
	when_show_hint = 'everytime',
}) => {

	const is_outline = color === 'blue_outline' || color === 'green_outline' || color === 'red_outline';

	const outline_width: string = width? (typeof width === 'number')? (width-2)+'px' : width : '118px';
	const outline_height: string =  height? (typeof height === 'number'? (height-2)+'px' : height) : '44px';

	const _width: string = width? (typeof width === 'number'? width+'px' : width) : '120px';
	const _height: string = height? (typeof height === 'number'? height+'px' : height) : '46px';
	const _font_size: string | undefined = font_size? (typeof font_size === 'number'? font_size + 'px' : font_size) : undefined;
	const _on_enter_action = on_enter_action? on_enter_action : action? action : undefined;

	const button_style: React.CSSProperties = {
		width: is_outline? outline_width : _width,
		minHeight: is_outline? outline_height : _height,
		fontSize:  _font_size,
		fontWeight: font_weight || 500,
		padding: width === 'auto'? '0 10px' : undefined,
		cursor: is_loading? 'wait' : blocked? 'default' : 'pointer',
	}

	const button_class: string = blocked? is_outline? `button_blocked_outline` : `button_blocked` : `button_${color}`;

	function show_hint(): boolean {
		if (hint) {
			if (when_show_hint === 'everytime'){
				return true;
			}
			if (blocked && (when_show_hint === 'blocked' || (when_show_hint && when_show_hint.length > 0 && when_show_hint.includes('blocked')))){
				return true;
			}
			if (is_loading && (when_show_hint === 'loading' || (when_show_hint && when_show_hint.length > 0 && when_show_hint.includes('loading')))){
				return true;
			}
			if (!blocked && !is_loading && (when_show_hint === 'default' || (when_show_hint && when_show_hint.length > 0 && when_show_hint.includes('default')))){
				return true;
			}
		}
		return false
	}


	return (
		<div
			className={class_name? button_class + ' ' + class_name : button_class}
			style={button_style}
			onKeyDown={e => { (_on_enter_action && !blocked && e.key === 'Enter') && _on_enter_action() }}   //  onKeyDown !== onKeyPress, e.key !== e.code
			// onKeyDown={e => { (_on_enter_action && !blocked && e.key === 'Enter') && setTimeout(() => { _on_enter_action() })}}   //  onKeyDown !== onKeyPress, e.key !== e.code
			onClick={() =>{ (!blocked && action) && action() }}     // causes troubles in select_option without timeout
			// onClick={() =>{(!blocked && action) && setTimeout(() => { action() })}}     // causes troubles in select_option without timeout
			tabIndex={ (tabindex && !blocked)? (typeof tabindex === 'boolean'? 0 : tabindex) : undefined}
			title={show_hint()? hint : undefined}
		>
			{children && children}
		</div>
	)
}

export default Button