import { Session } from '@chromia/ft4';
import { IClient } from 'postchain-client';

import { asset_balance, iFullfilled_account_details } from '../../chromia_interfaces';

export enum chromia_action_types {
	CLEAR_STATE = 'CHR/CLEAR_STATE',
	SET_ACCOUNT_DETAILS = 'CHR/SET_ACCOUNT_DETAILS',
	SET_ASSET_BALANCE = 'CHR/SET_ASSET_BALANCE',

	SET_CHR_CLIENT = 'CHR/SET_CHR_CLIENT',
	SET_SESSION = 'CHR/SET_SESSION',
}

export type chromia_action =
	clear_state_action |
	set_account_details_action |
	set_asset_balance_action |
	iSetChrClientAction |
	iSetSessionAction
;

interface clear_state_action {
	type: chromia_action_types.CLEAR_STATE
}

interface set_account_details_action {
	type: chromia_action_types.SET_ACCOUNT_DETAILS,
	payload: iFullfilled_account_details
}

interface set_asset_balance_action {
	type: chromia_action_types.SET_ASSET_BALANCE,
	payload: asset_balance
}

interface iSetChrClientAction {
	type: chromia_action_types.SET_CHR_CLIENT,
	payload: IClient
}

interface iSetSessionAction {
	type: chromia_action_types.SET_SESSION,
	payload: Session
}