import { iOrder_book_modal_state } from "../../interfaces";
import { obm_action, obm_action_types} from "./OBM_types";

const initial_state: iOrder_book_modal_state = {
	modal_id: 0,
	current_option_id: null,
	is_buttons_blocked: false,
}

export const OBM_reducer = ( state = initial_state, action: obm_action) : iOrder_book_modal_state => {
	switch (action.type){

		case obm_action_types.CALL_MODAL:
			return {...state, current_option_id: action.payload};

		case obm_action_types.CLOSE_MODAL:
			return {
				...initial_state,
				modal_id: state.modal_id+1,
				is_buttons_blocked: state.is_buttons_blocked,
			};

		case obm_action_types.SET_IS_BUTTONS_BLOCKED:
			return {...state, is_buttons_blocked: action.payload};

		default: return state;
	}
}