import { iBasic_state } from "../../interfaces";
import { basic_action, basic_action_types } from "./basic_types";

const initial_state: iBasic_state = {
	// current_step: 1,
	current_step: 2,	// temp
	// current_f_token: null,
	current_f_token: 'USDT',	// temp
	// current_u_token: null,
	current_u_token: 'BTC',	// temp
	current_direction: null,
	current_maturity_date: null,
	current_strike: null,
}

export const basic_reducer = ( state = initial_state, action: basic_action) : iBasic_state => {
	switch (action.type) {
		case basic_action_types.SET_CURRENT_STEP:
			switch (action.payload) {
				case 1:
					return initial_state;

				case 2:
					return {...state, current_step: 2, current_direction: null};

				case 3:
					return {...state, current_step: 3, current_maturity_date: null};

				case 4:
					return {...state, current_step: 4, current_strike: null};

				default:
					return state;
			}

		case basic_action_types.SET_CURRENT_F_TOKEN:
			return {...state, current_f_token: action.payload};

		case basic_action_types.SET_CURRENT_U_TOKEN:
			return {...state, current_u_token: action.payload, current_step: 2};

		case basic_action_types.SET_CURRENT_DIRECTION:
			return {...state, current_direction: action.payload, current_step: 3};

		case basic_action_types.SET_CURRENT_MATURITY_DATE:
			return {...state, current_maturity_date: action.payload, current_step: 4};

		case basic_action_types.SET_CURRENT_STRIKE:
			return {...state, current_strike: action.payload, current_step: 5};

		case basic_action_types.STEP_BACK:
			switch (state.current_step) {
				case 2: // choosed u_token
					return initial_state;

				case 3: // choosed direction
					return {...state, current_step: 2, current_direction: null};

				case 4: // choosed maturity
					return {...state, current_step: 3, current_maturity_date: null};

				case 5: // choosed strike
					return {...state, current_step: 4, current_strike: null};

				default:
					return state;
			}

		default:
			return state;
	}
}