import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { bsc } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { Web3Modal } from '@web3modal/react';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'

import { basename } from './settings';
import { store } from './store/store';

import './scss/index.scss';

import ColorModeHandler from './components/UI/color_mode_handler/color_mode_handler';
import EscapeHandler from './components/UI/escape_handler/escape_handler';
import Loader from './components/UI/loader/loader';
import ProviderChangesHandler from './components/UI/provider_changes_handler/provider_changes_handler';
import Header from './components/UI/header/header';

const App = lazy(() => import('./app'));
const PingPage = lazy(() => import('./components/ping_page/ping_page'));
const ShowStrikesPage = lazy(() => import('./components/show_strikes_page/show_strikes_page'));
const WagmiWrapper = lazy(() => import('./components/utilComponents/WagmiWrapper/WagmiWrapper'));


const chains = [bsc];
const projectId = 'c44eece3f0322485071b7ea5dfdeeee8';	// eopt

const { webSocketPublicClient } = configureChains([...chains], [publicProvider()]);
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

const config = createConfig({
	// Connectors here are needed for auto connect to work
	connectors: w3mConnectors({ projectId, chains }),
	autoConnect: true,
	// autoConnect: false,
	publicClient,
	webSocketPublicClient,
});
const ethereumClient = new EthereumClient(config, chains)


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	// <React.StrictMode>
	<>
        <WagmiConfig config={config}>
			<Provider store={store}>
				<Router basename={basename}>
					<Suspense fallback={<Loader/>}>
						<Switch>
							<Route exact path='/ping'>
								<PingPage/>
							</Route>
							<Route exact path='/strikes/show'>
								<ColorModeHandler/>
								<Header/>
								<ShowStrikesPage/>
							</Route>
							<Route>
								<ColorModeHandler/>
								<EscapeHandler/>
								<WagmiWrapper/>
								<ProviderChangesHandler/>
								<Header/>
								<App/>
							</Route>
						</Switch>
					</Suspense>
				</Router>
			</Provider>
		</WagmiConfig>

		<Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
	</>
	// </React.StrictMode>
);