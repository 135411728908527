import BN from "bn.js";

import { iOption, modal_name, iConfig_strike_date, iConfig_f_token, iConfig_u_token, iConfig, token_info } from "./interfaces";
import { store } from "./store/store";
import { KasUrl, blockchain, isProduction, projectName, settings_api, settings_api_2 } from "./settings";
import { app_action_types } from "./store/app/app_types";
import { iChrError } from "./chromia_interfaces";

type date_format =
	'YYYY-MM-DD' |
	'YYYY-MM-DD hh:mm' |
	'YYYY-MM-DD hh:mm:ss' |
	'DDMMMYY' |
	'DDmmmYY' |
	'mmm DD' |
	'YYYY.MM.DD' |
	'DD mmm YY, hh:mm' |
	'hh:mm'
;

export function get_human_date(ts: number, format: date_format): string {
	if(Number.isNaN(ts)) throw new Error('Error code: 6_9');

	let date = new Date(ts * 1000);
	let year = '' + date.getUTCFullYear();
	let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	let months_caps = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
	let month = date.getUTCMonth();
	let human_month = '0' + (date.getUTCMonth()+1);
	let day = '0' + date.getUTCDate();
	let hour = '0' + date.getUTCHours();
	let minutes = '0' + date.getUTCMinutes();
	let seconds = '0' + date.getUTCSeconds();
	
	switch (format) {
		case 'YYYY-MM-DD':
			return `${year}-${human_month.substring(human_month.length-2)}-${day.substring(day.length-2)}`;
		
		case 'YYYY-MM-DD hh:mm':
			return `${year}-${human_month.substring(human_month.length-2)}-${day.substring(day.length-2)} ${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}`;

		case 'YYYY-MM-DD hh:mm:ss':
			return `${year}-${human_month.substring(human_month.length-2)}-${day.substring(day.length-2)} ${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}:${seconds.substring(seconds.length-2)}`;

		case 'DDMMMYY':
			return `${day.substring(day.length-2)}${months_caps[month]}${year.substring(year.length-2)}`

		case 'DDmmmYY':
			return `${day.substring(day.length-2)}${months[month]}${year.substring(year.length-2)}`

		case 'mmm DD':
			return `${months[month]} ${day.substring(day.length-2)}`

		case 'YYYY.MM.DD':
			return `${year}.${human_month.substring(human_month.length-2)}.${day.substring(day.length-2)}`

		case 'DD mmm YY, hh:mm':
			return `${day.substring(day.length-2)} ${months[month]} ${year.substring(year.length-2)}, ${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}`

		case 'hh:mm':
			return `${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}`

		default:
			return `${year}-${human_month.substring(human_month.length-2)}-${day.substring(day.length-2)} ${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}:${seconds.substring(seconds.length-2)}`;
	}
}

export function get_human_date_period(ts: number): string {
	let now_ts = store.getState().app.system_timestamp;
	if (!now_ts) throw new Error('Error code: 6_4')

	let difference = ts - now_ts;
	
	// (X days/hours/minutes ago - ?)
	if (difference < 0) throw new Error('Error code: 6_5');
	// console.log('now: ', now_ts, '\nts: ', ts, '\ndifference: ', difference)
	return difference < 3600    // less than hour
		? 'in ' + Math.trunc( difference / 60 ) + ' minutes'
		: difference < 86400    // less than day
			? 'in ' + Math.trunc( difference / 60 / 60 ) + ' hours'
			: 'in ' + Math.trunc( difference / 60 / 60 / 24 ) + ' days' // more than day
}

export function val_to_BN(value: string | number, decimals?: number): BN {
	if(Number.isNaN(value)) throw new Error('Error code: 6_15');

	let dec = decimals || 18;
	let arr = value.toString().split('.');

	if (arr.length === 1) return (new BN(arr[0] + '0'.repeat(dec)));
	return new BN(arr[0] + arr[1].slice(0, dec).padEnd(dec, '0'));
}

export function BN_to_val(value: string | number, decimals?: number): string {
	if(Number.isNaN(value)) throw new Error('Error code: 6_16');

	let dec = decimals || 18;
	if (value === '0') return '0';
	let str = value.toString();
	let res = '';
	if (str[0] === '-') {
		res = '-';
		str = str.substring(1);
	}
	str = str.replace(/^0+/, '');
	if (str.length > dec) {
		res += str.substring(0, str.length - dec) + '.' + str.substring(str.length - dec);
	}
	else {
		res += '0.' + str.padStart(dec, '0');
	}
	res = res.replace(/0+$/, '');
	if (res[res.length - 1] === '.') return res.substring(0, res.length-1);
	// if (res[res.length - 1] === '.') return res + '0';
	return res;
}

/**
 * Throw the 'e' letter(exponential)
 * @returns formated value
 */
 export function fixExponential(value: string | number): string{
	const data = value.toString().split(/[eE]/);
	if (data.length === 1) return data[0];
  
	let z = '',
		sign = +value < 0 ? '-' : '',
		str = data[0].replace('.', ''),
		mag = Number(data[1]) + 1;
  
	if (mag < 0) {
		z = sign + '0.';
		while (mag++) z += '0';

		return z + str.replace(/^-/, '');
	}

	mag -= str.length;
	while (mag--) z += '0';

	return str + z;
}

export function get_token_by_currency(token_currency: string ): token_info {
	let res = store.getState().app.tokens.find(token => token.currency === token_currency);
	if (!res) throw new Error(`Error code: 6_2\ncurrency: ${token_currency}`);
	return res;
}

export function get_token_addr_by_currency(token_currency: string ): string {
	let res = store.getState().app.tokens.find(token => token.currency === token_currency);
	if (!res) throw new Error(`Error code: 6_2\ncurrency: ${token_currency}`);
	return res.address;
}

export function get_token_currency_by_addr(token_adr: string): string {
	let res = store.getState().app.tokens.find(token => token.address.toLowerCase() === token_adr.toLowerCase());
	if (!res) throw new Error(`Error code: 6_3\naddr: ${token_adr}`);
	return res.currency;
}

export function add_in_BN(a: number | string, b: number | string): string {
	return BN_to_val( ( val_to_BN(a).add( val_to_BN(b) ) ).toString() )
}

export function substract_in_BN(a: number | string, b: number | string): string {
	return BN_to_val( ( val_to_BN(a).sub( val_to_BN(b) ) ).toString() )
}

export function multiply_in_BN(a: number | string, b: number | string): string {
	return BN_to_val( BN_to_val(  ( val_to_BN(a).mul( val_to_BN(b) ) ).toString() ) );
}

export function to_fixed(value: number | string, fixed_number: number): string {    // not rounding
	if ( typeof(value) === 'number'){
		value = value.toString();
	}
	value = value.replace(/,/, '.'); // replace , to .
	let splitted = value.split('.');
	if (splitted[1]){
		for (let i=1; i<fixed_number; i++){
			splitted[1] = splitted[1] + '0';
		}
	}
	let res: string; 
	splitted.length === 2 ? res = splitted[0] + '.' + splitted[1].substring(0, fixed_number) : res = splitted[0];
	return res;
}

export function options_sorting(a: iOption, b: iOption): number {
	return (
		b.maturity_time - a.maturity_time ||  // 1 sort: mat_time (newest to oldest)
		// +(a.maturity_time < b.maturity_time) - +(b.maturity_time < a.maturity_time) ||  // 1 sort: mat_time (newest to oldest)   // not causes overflow for big numbers
		+(b.f_tok_curr < a.f_tok_curr) - +(a.f_tok_curr < b.f_tok_curr) ||  // 2 sort: f_tok (alphabetically)
		+(b.u_tok_curr < a.u_tok_curr) - +(a.u_tok_curr < b.u_tok_curr) ||  // 3 sort: u_tok (alphabetically)
		+a.strike - +b.strike ||  // 4 sort: strike (smallest to largest)
		// +(b.strike < a.strike) - +(a.strike < b.strike) ||  // 4 sort: strike (smallest to largest)  // not causes overflow for big numbers
		+b.option_type - +a.option_type     // 5 sort: option_type (call then put)
	)
}

export function get_opts_maturities(
	options: iOption[],
	f_token_filter?: string,
	u_token_filter?: string,
	opt_type_filter?: 'Call' | 'Put',
	only_config_maturities?: boolean,
): number[] {
	
	options = options.filter(option =>
		(
			f_token_filter
				? option.f_tok_curr === f_token_filter
				: true
		) && (
			u_token_filter
				? option.u_tok_curr === u_token_filter
				: true
		) && (
			opt_type_filter
				? option.option_type_text === opt_type_filter
				: true
		)
	)

	let options_maturities: number[] = [];
	for (let option of options){
		!options_maturities.includes(option.maturity_time) && options_maturities.push(option.maturity_time);
	}
	
	// remove dates that aren't in config
	if (only_config_maturities){
		let config_maturities = get_config_maturites();
		options_maturities = options_maturities.filter( options_maturity => config_maturities.includes(options_maturity));
	}
	
	return options_maturities.sort();
}

export function get_config_maturites(): number[] {
	const config = store.getState().app.config;
	if (!config) throw new Error('Error code: 6_0');
	return config.strikes.dates.map(date => date.ts);
}

export function get_opts_tokens(token: 'f' | 'u', opts: iOption[]): string[] {
	let tokens: string[] = [];
	opts.forEach(opt => {
		let interesting_token = token === 'f' ? opt.f_tok_curr : opt.u_tok_curr;
		if (!tokens.includes(interesting_token)) tokens.push(interesting_token);
	})
	return tokens.sort();
} 

export function get_config_date(ts: number): iConfig_strike_date {
	const config = store.getState().app.config;
	if (!config) throw new Error('Error code: 6_11');
	let date = config.strikes.dates.find(date => date.ts === ts);
	if (!date) throw new Error(`Error code: 6_12\nts: ${ts}`);
	return date
}

export function get_config_f_token(ts: number, f_token: string): iConfig_f_token {
	let token = get_config_date(ts).f_tokens.find(f_tok => f_tok.currency === f_token);
	if (!token) throw new Error('Error code: 6_13')
	return token;
}

export function get_config_f_tokens(ts: number): string[] {
	return get_config_date(ts).f_tokens.map(f_token => f_token.currency);
}

export function get_config_u_token(ts: number, f_token: string, u_token: string): iConfig_u_token {
	let token = get_config_f_token(ts, f_token).u_tokens.find(u_tok => u_tok.currency === u_token);
	if (!token) throw new Error('Error code: 6_14')
	return token;
}

export function get_config_u_tokens(ts: number, f_token: string): string[] {
	return get_config_f_token(ts, f_token).u_tokens.map(token => token.currency);
}

export function get_config_strikes(ts: number, f_token: string, u_token: string): number[] {
	return get_config_u_token(ts, f_token, u_token).strikes.map(strike => +strike);
}

export function get_decimals_by_currency(token: string): number {
	let current_token = store.getState().app.tokens.find(tok => tok.currency === token);
	if (!current_token) throw new Error('Error code: 6_6');
	return current_token.dec;
}

export function get_option_info(opt_id: number, prefix? : string) {
	const { all_options } = store.getState().app;

	if (all_options.length === 0) throw new Error('Error code: 6_8');

	for (let i=0; i<all_options.length; i++){
		if (all_options[i].opt_id === opt_id){
			let curr_opt = all_options[i];
			return (
				(prefix ? prefix : '') +
				curr_opt.u_tok_curr + 
				'-' + 
				curr_opt.f_tok_curr + 
				' ' + 
				curr_opt.option_type_text + 
				', maturity: ' +
				get_human_date(curr_opt.maturity_time, 'YYYY-MM-DD') + 
				', strike: ' +
				curr_opt.strike
			)
		}
	}
}

export function add_close_modal_listener(modal_name: modal_name, func: () => void) {
	document.addEventListener(`close_${modal_name}`, func);
}

export function remove_close_modal_listener(modal_name: modal_name, func: () => void) {
	document.removeEventListener(`close_${modal_name}`, func);
}

export function calculate_order_current_price(
	order_response: {
		ord_type: boolean,
		ref_price: string,
		delta: string,
		ord_price: string,
		min_max_price: string,
	},
	curr_price: number | string
): string {
	let f_tok_price: string = '';
	if (+order_response.delta !== 0){    // dynamic order
		f_tok_price = add_in_BN( order_response.ord_price, multiply_in_BN( substract_in_BN( curr_price, order_response.ref_price ), order_response.delta ) );
	} else {    // static order
		f_tok_price = order_response.ord_price;
	}

	return (
		order_response.ord_type
			? +f_tok_price < +order_response.min_max_price   // sell
				? order_response.min_max_price      // min
				: f_tok_price
			: +f_tok_price > +order_response.min_max_price   // buy
				? order_response.min_max_price      // max
				: +f_tok_price > 0
					? f_tok_price
					: '0.01'
	)
}

export async function get_api(): Promise<{api: string, config: iConfig, index: number} | undefined> {
	const apis = [settings_api, settings_api_2];
	for (let i=0; i < apis.length; i++){
		try{
			let response = await fetch(apis[i] + '/info/');
			if (response.ok) return { api: apis[i], config: await response.json(), index: i };
		} catch {}
	}
}

export async function get_api_with_balancer(): Promise<{api: string, config: iConfig, index: number} | undefined> {
	const apis = [settings_api, settings_api_2];
	let response = await Promise.any( apis.map(async (api, index) => {
		try {
			let response = await fetch(api + '/info/');
			if (response.ok) return { api, response, index };
		} catch {}
	}) );

	return response? { api: response.api, config: await response.response.json(), index: response.index } : undefined;
}

export const connectKAS = async () => {
	const username = 'hello';
	const password = 'world';

	const res = await fetch(KasUrl + 'users/auth', {
			method: 'POST',
			headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: `username=${username}&password=${password}`
	})

	if (res.ok) {
		const accessToken = (await res.json()).access_token;
		store.dispatch({
			type: app_action_types.SET_KAS_ACCESS_TOKEN,
			payload: accessToken
		});
	}
}

export const logErrorKas = async (error: string) => {
	switch (blockchain) {
		case 'chromia':
			const err = error as unknown as iChrError;
			if (err.originalError.info.error.code === 4001) return;
	}

	const accessToken =  store.getState().app.KasAccessToken;

	if (isProduction && accessToken) {
		return fetch(`${KasUrl}users/send_message?project_name=${projectName}&text=${error}`, {
			method: 'GET',
			headers: {
					'Authorization': `bearer ${accessToken}`
			},
		})
	}

	throw new Error(error);
}