export enum IM_action_types {
	CLOSE_MODAL = 'IM/CLOSE_MODAL',
	CALL_INFO_MODAL = 'IM/CALL_INFO_MODAL',
}

export type IM_action =  
	close_modal_action |
	call_info_modal_action
;

interface close_modal_action {
	type: IM_action_types.CLOSE_MODAL
}

interface call_info_modal_action {
	type: IM_action_types.CALL_INFO_MODAL,
	payload: string
}