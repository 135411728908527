import { maturity_date } from "../../interfaces";

export enum pro_action_types {
	SET_CURRENT_F_TOKEN = 'PRO/SET_CURRENT_F_TOKEN',
	SET_CURRENT_U_TOKEN = 'PRO/SET_CURRENT_U_TOKEN',
	SET_CURRENT_MATURITY_DATE = 'PRO/SET_CURRENT_MATURITY_DATE',
	CLEAR_STATE = 'PRO/CLEAR_STATE',
	SET_OPTIONS_PERIOD = 'PRO/SET_OPTIONS_PERIOD',
}

export type pro_action =
	set_current_f_token_action |
	set_current_u_token_action |
	set_current_maturity_date_action |
	clear_state_action |
	set_options_period_action
;

interface set_current_f_token_action {
	type: pro_action_types.SET_CURRENT_F_TOKEN,
	payload: string | null
}

interface set_current_u_token_action {
	type: pro_action_types.SET_CURRENT_U_TOKEN,
	payload: string | null
}

interface set_current_maturity_date_action {
	type: pro_action_types.SET_CURRENT_MATURITY_DATE,
	payload: maturity_date | null
}

interface clear_state_action {
	type: pro_action_types.CLEAR_STATE
}

interface set_options_period_action {
	type: pro_action_types.SET_OPTIONS_PERIOD,
	payload: 'daily' | 'weekly'
}