import { app_action_types } from "../app/app_types"
import { Dispatch } from "../store"
import { RUBM_action_types } from "./RUBM_types"

export function close_request_uncreate_borrow_modal() {
	return (dispatch : Dispatch) => {
		dispatch({
			type: RUBM_action_types.CLOSE_MODAL
		});
		dispatch({
			type: app_action_types.CLOSE_CHOOSEN_WINDOW,
			payload: 'request_uncreate_borrow'
		})
	}
}

export function call_request_uncreate_borrow_modal(type: 'request' | 'uncreate' | 'borrow', balance_opt_id: number | string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: RUBM_action_types.CALL_MODAL,
			payload: {type, balance_opt_id}
		});
		dispatch({  // look for another way
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: 'request_uncreate_borrow'
		});
	}
}