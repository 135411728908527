import { app_action_types } from "../app/app_types"
import { Dispatch } from "../store"
import { MOM_action_types } from "./MOM_types"

export function close_make_order_modal() {
	return (dispatch : Dispatch) => {
		dispatch({
			type: MOM_action_types.CLOSE_MODAL
		});
		dispatch({
			type: app_action_types.CLOSE_CHOOSEN_WINDOW,
			payload: 'make_order'
		});
	}
}

export function call_create_make_order_modal(opt_id: number | string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: MOM_action_types.CALL_CREATE_MODAL,
			payload: opt_id
		});
		dispatch({
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: 'make_order'
		});
	}
}

export function call_modify_make_order_modal(opt_id: number | string, ord_id: number | string, is_sell: boolean) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: MOM_action_types.CALL_MODIFY_MODAL,
			payload: {
				opt_id,
				order: {
					id: ord_id,
					is_sell
				}
			}
		});
		dispatch({
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: 'make_order'
		});
	}
}