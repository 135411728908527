import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"

import { Balance } from "../../components/_models/balance"
import { Order } from "../../components/_models/order"
import { iAll_prices_price, iBalance, iBalance_response, iConfig, iConfig_strikes, iConfig_token, iEOPT_contract, iHistory_recording, iHistory_response_recordings, iOption, iOption_response, iOrder, iOrder_response, iToken_contract, iWeb3_provider, modal_name } from "../../interfaces"
import { blockchain, is_static_time } from "../../settings"
import { Dispatch, store } from "../store"
import { Option } from '../../components/_models/option';
import { History_recording } from "../../components/_models/history"
import { getChrTime } from "../../utils/chromia/ft4/queries"

import { app_action_types } from "./app_types"
import { pro_action_types } from "../pro/pro_types"
import { basic_action_types } from "../basic/basic_types"
import { AM_action_types } from "../approve_modal/AM_types"
import { obm_action_types } from "../order_book_modal/OBM_types"
import { chromia_action_types } from "../chromia/chromia_types"
import { UIActionTypes } from "../UI/UITypes"


export function set_client_addr(addr: string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_CLIENT_ADDR,
			payload: addr
		})
	}
}

export function set_api(api: string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_API,
			payload: api
		})
	}
}

export function set_config(config: iConfig) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_CONFIG,
			payload: config
		})
	}
}

export function set_EOPT_contract(contract: iEOPT_contract) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_EOPT_CONTRACT,
			payload: contract
		})
	}
}

export function set_token(config_token: iConfig_token, contract: iToken_contract, type: 'funding' | 'underlying') {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_TOKEN,
			payload: {
				currency: config_token.name,
				address: config_token.adr,
				dec: config_token.dec,
				show_dec: config_token.show_dec,
				type,
				contract
			}
		})
	}
}

export function set_system_timestamp(timestamp: number) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_SYSTEM_TIMESTAMP,
			payload: timestamp
		})
	}
}

export function set_web3_provider(provider: iWeb3_provider) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_WEB3_PROVIDER,
			payload: provider
		})
	}
}

export function disconnect_app() {   // clears all states
	const { web3_provider } = store.getState().app;

	switch (blockchain) {
		case 'binance':
			localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
			if (localStorage.getItem('walletconnect') && web3_provider && web3_provider.disconnect){      // causes bugs without this check
				web3_provider.disconnect();
			}
			break;

		case 'chromia':
			// localStorage.removeItem('CHROMIA_CONNECT_CACHED_DETAILS');
			break;

		default:
			break;
	}

	return (dispatch: Dispatch) => {
		setTimeout(() => {  // otherwise causes problems with reconnect
			dispatch({
				type: pro_action_types.CLEAR_STATE
			});
			dispatch({
				type: chromia_action_types.CLEAR_STATE
			});
			dispatch({
				type: basic_action_types.SET_CURRENT_STEP,
				payload: 1
			});
			dispatch({
				type: AM_action_types.CLOSE_APPROVE_MODAL
			});
			dispatch({
				type: obm_action_types.CLOSE_MODAL
			});
			dispatch({
				type: app_action_types.CLEAR_APP_STATE,
			});
			dispatch({
				type: UIActionTypes.CLEAR_STATE,
			})
		});
	}
}

export function set_is_pro(is_pro: boolean) {
	localStorage.setItem('eopt_mode', is_pro? 'pro' : 'basic');
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_IS_PRO,
			payload: is_pro
		})
	}
}

export function set_token_price(currency: string, price: number) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_TOKEN_PRICE,
			payload: {currency, price}
		})
	}
}

export function set_current_options(options: iOption[]) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_CURRENT_OPTIONS,
			payload: options
		})
	}
}

export function set_client_balances(balances: iBalance[]) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_CLIENT_BALANCES,
			payload: balances
		})
	}
}

export const get_prices_and_time_thunk = (): ThunkAction<Promise<void>, any, unknown, AnyAction> => {
	return async (dispatch: Dispatch) => {
		const { api } = store.getState().app;

		if (!api) throw new Error(`Error code: 4_0`);

		let response = await fetch (api + '/prices/');
		if (!response.ok) throw new Error('Error code: 4_3');
		let all_prices: iAll_prices_price[] = await response.json();
		for (let price of all_prices){
			dispatch({
				type: app_action_types.SET_TOKEN_PRICE,
				payload: {currency: price.name ,price: price.price}
			})
		}

		let res_time = 0;
		switch (blockchain) {
			case 'binance':
				// for static time tn
				let contract_ts = 0;
				if (is_static_time){
					const contract = store.getState().app.EOPT_contract;
					if (!contract) throw new Error(`Error code: 4_0`);
					contract_ts = + await contract.methods.now_time().call();
				}
				res_time = is_static_time? contract_ts : all_prices[0].timestamp
				break;

			case 'chromia':
				res_time = await getChrTime();
				break;

			default:
				throw new Error();
		}

		dispatch({
			type: app_action_types.SET_SYSTEM_TIMESTAMP,
			// payload: is_static_time? contract_ts : all_prices[0].timestamp
			payload: res_time
			// payload: 1648120746
		})
	}
}

export const get_options_thunk = (): ThunkAction<Promise<void>, any, unknown, AnyAction> => {
	return async (dispatch: Dispatch) => {
		const { api, system_timestamp } = store.getState().app;

		if (!api || system_timestamp === null) throw new Error(
			'Error code: 4_1' +
			(api? '' : '\napi: -') +
			(system_timestamp? '' : '\ntime: -')
		);

		let response = await fetch (api + `/options/all/orders?maturity=${system_timestamp}`);
		if (!response.ok) throw new Error('Error code: 4_4');
		let response_options: iOption_response[] = await response.json();
		let options: iOption[] = [];
		for (let response_option of response_options){
			options.push( new Option(response_option) );
		}

		dispatch({
			type: app_action_types.SET_CURRENT_OPTIONS,
			payload: options
		})
	}
}

export const get_client_balances_thunk = (): ThunkAction<Promise<void>, any, unknown, AnyAction> => {
	return async (dispatch: Dispatch) => {
		const { api, client_addr } = store.getState().app;

		if (!api || !client_addr) throw new Error(
			'Error code: 4_2' +
			(api? '' : '\napi: -') +
			(client_addr? '' : '\naddr: -')
		);

		let json = await fetch(api + `/clients/${client_addr}/balance`);
		if (!json.ok) throw new Error('Error code: 4_5');
		let response: iBalance_response[] = await json.json();
		let balances: iBalance[] = [];
		for (let balance_part of response) {
			balances.push(new Balance(balance_part))
		};

		dispatch({
			type: app_action_types.SET_CLIENT_BALANCES,
			payload: balances
		})
	}
}

export const get_client_orders_thunk = (): ThunkAction<Promise<void>, any, unknown, AnyAction> => {
	return async (dispatch: Dispatch) => {
		const { api, client_addr } = store.getState().app;

		if (!api || !client_addr) throw new Error(
			'Error code: 4_9' +
			(api? '' : '\napi: -') +
			(client_addr? '' : '\naddr: -')
		);

		let json = await fetch(api + `/clients/${client_addr}/orders`);
		if (!json.ok) throw new Error('Error code: 4_6');
		let response: iOrder_response[] = await json.json();
		let orders: iOrder[] = [];
		for (let order_response of response) {
			orders.push(new Order(order_response));
		};

		dispatch({
			type: app_action_types.SET_CLIENT_ORDERS,
			payload: orders
		})
	}
}

export function set_dark_mode(state: boolean) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_DARK_MODE,
			payload: state
		})
	}
}

export const get_client_history_thunk = (): ThunkAction<Promise<void>, any, unknown, AnyAction> => {
	return async (dispatch: Dispatch) => {
		const { api, client_addr } = store.getState().app;

		if (!api || !client_addr) throw new Error(
			'Error code: 4_10' +
			(api? '' : '\napi: -') +
			(client_addr? '' : '\naddr: -')
		);

		dispatch({
			type: app_action_types.SET_IS_HISTORY_UPDATING,
			payload: true,
		})

		let response_1 = await fetch(api + `/options/`);  // all time
		if (!response_1.ok) throw new Error('Error code: 4_7');
		let response_options: iOption_response[] = await response_1.json();
		let options: iOption[] = [];
		for (let response_option of response_options){
			options.push( new Option(response_option) );
		}
		dispatch({
			type: app_action_types.SET_ALL_OPTIONS,
			payload: options
		})


		let response_2 = await fetch(api + `/clients/${client_addr}/history`);
		if (!response_2.ok) throw new Error('Error code: 4_8');
		let history_response: iHistory_response_recordings[] = await response_2.json();

		let history: iHistory_recording[] = [];
		for (let recording_response of history_response) {
			history.push(new History_recording(recording_response));
		};

		dispatch({
			type: app_action_types.SET_HISTORY_RECORDINGS,
			payload: history
		})
	}
}

export function open_new_window(name: modal_name) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: name
		})
	}
}

export function close_choosen_window(choosen_window: modal_name) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.CLOSE_CHOOSEN_WINDOW,
			payload: choosen_window
		})
	}
}

export function close_create_option_modal() {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.CLOSE_CHOOSEN_WINDOW,
			payload: 'create_option'
		})
		dispatch({
			type: app_action_types.CLOSE_CREATE_OPTION_MODAL
		})
	}
}

export function set_options_period_options(period: 'all' | 'daily' | 'weekly' | 'non-standart') {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_OPTIONS_PERIOD,
			payload: period
		})
	}
}

export function update_config_strikes(strikes: iConfig_strikes) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.UPDATE_STRIKES,
			payload: strikes
		})
	}
}

export const setKasAccessToken = (token: string) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: app_action_types.SET_KAS_ACCESS_TOKEN,
			payload: token
		})
	}
}