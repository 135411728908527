import { iPromise_fork } from "../../interfaces"
import { app_action_types } from "../app/app_types"
import { Dispatch } from "../store"
import { AM_action_types } from "./AM_types"

export function set_approve_token(token: string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: AM_action_types.SET_APPROVE_TOKEN,
			payload: token
		})
	}
}

export function set_approve_amount(amount: string) {
	return (dispatch : Dispatch) => {
		dispatch({
			type: AM_action_types.SET_APPROVE_AMOUNT,
			payload: amount
		})
	}
}

export function call_approve(approve_token: string, approve_amount: string, approve_promise_fork: iPromise_fork , is_option_blocked?: boolean) {
	return (dispatch: Dispatch) => {
		dispatch({
			type: AM_action_types.CALL_APPROVE,
			payload: {approve_token, approve_amount, approve_promise_fork, is_option_blocked}
		})
		dispatch({
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: 'approve'
		})
	}
}

export function open_pure_approve_modal() {
	return (dispatch: Dispatch) => {
		dispatch({
			type: app_action_types.OPEN_NEW_WINDOW,
			payload: 'approve'
		})
	}
}

export function close_approve_modal() {
	return (dispatch: Dispatch) => {
		dispatch({
			type: AM_action_types.CLOSE_APPROVE_MODAL
		})
		dispatch({
			type: app_action_types.CLOSE_CHOOSEN_WINDOW,
			payload: 'approve'
		})
	}
}