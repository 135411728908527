import { iMake_order_modal_state } from "../../interfaces";
import { MOM_action, MOM_action_types } from "./MOM_types";

const initial_state: iMake_order_modal_state = {
	modal_id: 0,
	type: null,
	opt_id: null,
	order: null,
}

export const MOM_reducer = ( state = initial_state, action: MOM_action ) : iMake_order_modal_state => {
	switch (action.type) {
		case MOM_action_types.CLOSE_MODAL:
			return {...initial_state, modal_id: state.modal_id+1};

		case MOM_action_types.CALL_CREATE_MODAL:
			return {...state, opt_id: action.payload, type: 'Create'};

		case MOM_action_types.CALL_MODIFY_MODAL:
			return {
				...state,
				opt_id: action.payload.opt_id,
				order: action.payload.order,
				type: 'Modify'
			};

		default:
			return state;
	}
}