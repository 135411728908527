export enum UIActionTypes {
	CLEAR_STATE = 'UI/CLEAR_STATE',
	SET_INITED = 'UI/SET_INITED',
	SET_IS_CHR_REGISTERED = 'UI/SET_IS_CHR_REGISTERED',
}

export type UIAction =
	clearStateAction |
	setIsInitedAction |
	setIsChrRegisteredAction
;

interface clearStateAction {
	type: UIActionTypes.CLEAR_STATE,
}

interface setIsInitedAction {
	type: UIActionTypes.SET_INITED,
}

interface setIsChrRegisteredAction {
	type: UIActionTypes.SET_IS_CHR_REGISTERED,
	payload: boolean
}